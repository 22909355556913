import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { BsCheck2 } from "react-icons/bs";
import classNames from "classnames";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button'
import AuthFooter from '../../components/Common/AuthFooter'
import ErrorAlert from '../../components/Alert/ErrorAlert';
import OTPVerificationModal from '../../components/Modal/OTPVerificationModal';

// Images
import { logo, authentication } from '../../assets/images'

// API Service
import { signUpService } from '../../service/auth.service';
import { allCountryService } from '../../service/country.service';

const SignUp = () => {
    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [otpVerificationModal, setOTPVerificationModal] = useState(false)

    const { register, watch, setValue, getValues, setError, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });
    const watchPassword = watch("password")
    const watchCountryId = watch("country_id")

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
                if (result?.data?.data?.length > 0) {
                    setValue("country_id", result?.data?.data[0]?.id)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibility
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function handleShowConfirmPassword
        * @params
        * @description toggle the confirm password input field visibility
    */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
        * @function handleOTPVerificationModal
        * @params
        * @description set the visibility of otp verification modal
    */
    const handleOTPVerificationModal = () => {
        setOTPVerificationModal(!otpVerificationModal)
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to signup the player
    */
    const onSubmit = async (formdata) => {
        if (formdata.password !== formdata.password_confirmation) {
            setError('password', {
                type: "manual",
                message: 'Please make sure your password match.',
            })
            setError('password_confirmation', {
                type: "manual",
                message: 'Please make sure your password match.',
            })
            return;
        }
        const data = new FormData()
        data.append('username', formdata?.username)
        data.append('email', formdata?.email)
        data.append('phone', formdata?.phone)
        data.append('country_id', formdata?.country_id)
        data.append('password', formdata?.password)
        data.append('password_confirmation', formdata?.password_confirmation)
        try {
            setLoading(true)
            const result = await signUpService(data);
            if (result?.data?.status) {
                handleOTPVerificationModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Sign Up | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='pe-lg-0'>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" />
                    </Link>
                    <Row className="justify-content-center flex-grow-1 w-100 mt-5">
                        <Col xs={12} sm={7} className='mb-4'>
                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <span className='fs-30 fw-bold'>Sign Up</span>
                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>Username*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Username"
                                        {...register("username", {
                                            required: "Username is required"
                                        })}
                                    />
                                    {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>Email address*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                                </Form.Group>

                                {countryList.length > 0 &&
                                    <Form.Group className="my-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <InputGroup.Text className='p-0 border-0'>
                                                <Form.Select
                                                    className='border-end-0'
                                                    {...register("country_id")}
                                                >
                                                    {countryList.map((item, index) => {
                                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                                    })}
                                                </Form.Select>
                                            </InputGroup.Text>
                                            <Form.Control
                                                className='border-start-0 ps-0'
                                                type="tel"
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Phone is required",
                                                    minLength: {
                                                        value: 9,
                                                        message: "Phone Number should be 9 digit minimum"
                                                    },
                                                    maxLength: {
                                                        value: 11,
                                                        message: "Phone Number should be 11 digit maximum"
                                                    }
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>
                                }

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required"
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                    {watchPassword && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/i.test(watchPassword) &&
                                        <div className='d-flex flex-column align-items-start mt-1'>
                                            <div className='d-flex align-items-center'>
                                                <ErrorText className='mb-3'>Your password must contain</ErrorText>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', { 'text-success': /^(?=.*[A-Z])/.test(watchPassword) && /^(?=.*[a-z])/.test(watchPassword), 'text-secondary': !/^(?=.*[A-Z])/.test(watchPassword) && !/^(?=.*[a-z])/.test(watchPassword) })}><BsCheck2 className='me-2' size={20} />a lowercase and uppercase letter (a-z,A-Z)</span>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', { 'text-success': /^(?=.*[0-9])/.test(watchPassword), 'text-secondary': !/^(?=.*[0-9])/.test(watchPassword) })}><BsCheck2 className='me-2' size={20} />a number</span>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', { 'text-success': /^(?=.*\W)/.test(watchPassword), 'text-secondary': !/^(?=.*\W)/.test(watchPassword) })}><BsCheck2 className='me-2' size={20} />a special character($&@!#%^8)</span>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', { 'text-success': watchPassword.length >= 8, 'text-secondary': watchPassword.length < 8 })}><BsCheck2 className='me-2' size={20} />at least 8 characters</span>
                                            </div>
                                        </div>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Confirm Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password_confirmation', {
                                                required: "Confirm Password is required",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="mb-3 mt-4">
                                    <PrimaryButton disabled={loading} loading={loading} type="submit">Sign Up</PrimaryButton>
                                </Form.Group>
                            </Form>
                            <hr className='mx-5 my-3' />

                            <div className='text-center mt-4 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Already have an account?
                                    <Link to='/login' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Sign In</span>
                                    </Link>
                                </span>
                            </div>
                        </Col>
                        <AuthFooter />
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>

        {/* OTP Verification Modal */}
        <OTPVerificationModal
            show={otpVerificationModal}
            handleClose={handleOTPVerificationModal}
            country={countryList.find(item => item.id === parseInt(watchCountryId))?.code}
            phone={getValues("phone")}
            signup={true}
        />
    </HelmetProvider>
};

export default SignUp;