import { Modal, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Custom Component
import { PrimaryButton } from '../Button';

// Images
import { Pending } from '../../assets/images/svg';

const PaymentPendingModal = (props) => {
    return <Modal show={props.show} onHide={props.handleClose} backdrop="static" size='lg' centered>
        <Modal.Header className='d-flex flex-column justify-content-center align-items-center border-0 bg-warning text-light px-0 py-5 position-relative' closeButton={false}>
            <Pending />
            <span className='fs-30 fw-600 mt-3 mb-2'>Payment pending</span>
            <span className='text-center'>Your payment status will be updated shortly</span>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            {props?.tournament?.category_type === "INDIVIDUAL" &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Original Price</span>
                    {props?.tournament?.player_details?.length > 0 ? <span>RM{parseFloat(props?.tournament?.player_details[0].event_price).toFixed(2)}</span> : <span>RM0.00</span>}
                </div>
            }
            {props?.tournament?.category_type === "TEAM" &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Team Event Price</span>
                    <span>RM{parseFloat(props?.tournament?.team_event_price || 0).toFixed(2)}</span>
                </div>
            }
            <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                <span>Players</span>
                <span>{props?.tournament?.player_details?.length || 0}</span>
            </div>
            {props?.tournament?.category_type === "INDIVIDUAL" && props?.tournament?.player_details &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Total Price</span>
                    {props?.tournament?.player_details?.length > 0 ? <span>RM{parseFloat(props?.tournament?.player_details[0].event_price).toFixed(2)}</span> : <span>RM0.00</span>}
                </div>
            }
            {props?.tournament?.category_type === "TEAM" && props?.tournament?.player_details &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Total Price</span>
                    <span>RM{parseFloat(props?.tournament?.team_event_price || 0).toFixed(2)}</span>
                </div>
            }
            <Row className='mb-4 w-100 mt-3 justify-content-center'>
                <Col xs={6} md={4}>
                    <Link to='/tournament-history'>
                        <PrimaryButton className='fs-14 fw-500'>History</PrimaryButton>
                    </Link>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
};

export default PaymentPendingModal;