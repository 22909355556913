import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allCountryService } from '../../service/country.service';
import { sendMessageService } from '../../service/contactus.service';

// Images
import { contactus } from "../../assets/images";

const ContactUs = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([])

    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to send message
    */
    const onSubmit = async (formdata) => {
        const data = new FormData()
        data.append('first_name', formdata?.first_name)
        data.append('last_name', formdata?.last_name)
        data.append('email', formdata?.email)
        data.append('phone', formdata?.phone)
        data.append('country', formdata?.country)
        data.append('message', formdata?.message)
        try {
            setLoading(true)
            const result = await sendMessageService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                reset()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Contact Us | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container className='my-4'>
            <BiArrowBack role="button" size={20} onClick={() => navigate(-1)} />
            <Row className='mt-4'>
                <Col lg={6} className='d-none d-lg-block'>
                    <img className='img-fluid' src={contactus} alt='Contact Us' />
                </Col>
                <Col lg={6} className='ps-lg-5'>
                    <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                        <p className='fs-30 fw-bold'>Contact us</p>
                        <span className='fs-20 fw-400 text-secondary'>Our friendly team would love to hear from you.</span>

                        <Row>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mt-4">
                                    <Form.Label className='fs-14 fw-500'>First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="First name"
                                        {...register("first_name", {
                                            required: "First name is required"
                                        })}
                                    />
                                    {errors.first_name && <ErrorText>{errors.first_name.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mt-4">
                                    <Form.Label className='fs-14 fw-500'>Last name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last name"
                                        {...register("last_name", {
                                            required: "Last name is required"
                                        })}
                                    />
                                    {errors.last_name && <ErrorText>{errors.last_name.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="you@team.com"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                            />
                            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                        </Form.Group>

                        {countryList.length > 0 &&
                            <Form.Group className="my-4">
                                <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                <InputGroup className='rounded'>
                                    <InputGroup.Text className='p-0 border-0'>
                                        <Form.Select
                                            className='border-end-0'
                                            {...register("country")}
                                        >
                                            {countryList.map((item, index) => {
                                                return <option value={item?.id} key={index}>{item?.code}</option>
                                            })}
                                        </Form.Select>
                                    </InputGroup.Text>
                                    <Form.Control
                                        className='border-start-0 ps-0'
                                        type="tel"
                                        placeholder="Enter Phone number"
                                        {...register("phone", {
                                            required: "Phone is required"
                                        })}
                                    />
                                </InputGroup>
                                {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                            </Form.Group>
                        }

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Leave us a message..."
                                {...register("message", {
                                    required: "Message is required",
                                })}
                            />
                            {errors.message && <ErrorText>{errors.message.message}</ErrorText>}
                        </Form.Group>

                        <Form.Check
                            type='checkbox'
                            label={<span role='button' className='fw-500 text-secondary'>
                                You agree to our friendly <Link to='/privacy-policy' className='text-decoration-none'>privacy policy</Link>.
                            </span>}
                            id={`agree`}
                            required
                        />

                        <Form.Group className="mb-3 mt-4">
                            <PrimaryButton disabled={loading} loading={loading} type="submit">Send message</PrimaryButton>
                        </Form.Group>
                    </Form>

                    <p className='fs-14 fw-bold mb-0'>ATHLETES FOR ATHLETES SOLUTIONS SDN. BHD. (1376686-D)</p>
                    <p className='fs-14 mb-0'>Phone: 016-2930845</p>
                    <p className='fs-14 mb-0'>Email: <a href="mailto:hello@afa.community" className='text-decoration-none'>hello@afa.community</a></p>
                    <p className='fs-14 mb-0'>Lot 3, Jalan 51/203, Pjs 51,</p>
                    <p className='fs-14 mb-0'>46050, Petaling Jaya,</p>
                    <p className='fs-14 mb-0'>Selangor, Malaysia.</p>
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default ContactUs;