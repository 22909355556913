import { useState, useEffect, useRef } from 'react'
import { Row, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';
import { CSVLink } from "react-csv";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import moment from 'moment';
import { debounce } from "lodash";
import classNames from "classnames";

// Custom Component
import NoPlayer from "./NoPlayer";
import { PrimaryButton } from '../Button';
import Receipt from '../Receipt/Receipt';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';
import AddPlayerModal from '../Modal/AddPlayerModal';
import EditPlayerModal from '../Modal/EditPlayerModal';

// Images
import { GenerateReceipt } from "../../assets/images/svg";

// Fonts
import PoppinsRegular from '../../assets/fonts/PoppinsRegular';
import PoppinsMedium from '../../assets/fonts/PoppinsMedium';
import PoppinsBold from '../../assets/fonts/PoppinsBold';

// Utils
import { convertEventType, convertAgeGroup, convertGender } from '../../utils/helper';

// API Service
import { allPlayerOrganizerService, allPlayerCustomerService, singleReceiptOrganizerService, publishPlayerService, withdrawPlayerService } from '../../service/tournament.service';

// Redux-Functions
import { setPdfLoading } from "../../redux/tournamentSlice";
import { selectUser } from "../../redux/userSlice";

const MyPlayer = (props) => {
    let { tournamentId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [loading, setLoading] = useState(false);
    const [playerList, setPlayerList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [selectedGender, setSelectedGender] = useState('');
    const [csvData, setCSVData] = useState([]);
    const [receiptInfo, setReceiptInfo] = useState();
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [pdfContentShow, setPdfContentShow] = useState(false)
    const [editPlayerModal, setEditPlayerModal] = useState(false)
    const [addPlayerModal, setAddPlayerModal] = useState(false)

    const pdfRef = useRef(null);

    const csvHeaders = [
        {
            label: 'User name',
            key: 'user_name'
        },
        {
            label: 'Player Name',
            key: 'player_name'
        },
        {
            label: 'Club Name',
            key: 'club_name'
        },
        {
            label: 'Team Id',
            key: 'team_id'
        },
        ...(props?.tournament?.sports_type === "PICKLEBALL" ? [{
            label: 'DUPR Rating',
            key: 'dupr_rating'
        }] : []),
        {
            label: 'Email address',
            key: 'player_email'
        },
        {
            label: 'Phone number',
            key: 'player_contact'
        },
        {
            label: 'Events',
            key: 'event_type'
        },
        {
            label: 'Gender',
            key: 'player_gender'
        },
        {
            label: 'Player Age',
            key: 'player_dob'
        },
        {
            label: 'IC/Passport number',
            key: 'player_idcard'
        },
        {
            label: 'Country',
            key: 'nationality'
        },
        {
            label: 'User Phone no',
            key: 'phone'
        },
        {
            label: 'T-shirt name',
            key: 't_shirt_name'
        },
        {
            label: 'T-shirt size',
            key: 't_shirt_size'
        },
        {
            label: 'Status',
            key: 'player_status'
        }
    ];

    const { register, watch, setValue } = useForm({
        mode: "all",
        defaultValues: {
            event_type: '',
            keyword: ''
        }
    })

    const watchEventType = watch("event_type")
    const watchKeyword = watch("keyword")

    useEffect(() => {
        if (receiptInfo) {
            handleDownload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiptInfo])

    useEffect(() => {
        if (tournamentId && user?.type === 'organizer') {
            fetchAllOrganizerPlayerList()
        } else {
            fetchAllCustomerPlayerList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, user, props])

    useEffect(() => {
        if (tournamentId && user?.type === 'organizer') {
            fetchOrganizerPlayerList(currentPage + 1);
        } else {
            fetchCustomerPlayerList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, currentPage])

    useEffect(() => {
        if (tournamentId && user?.type === 'organizer') {
            if (currentPage === 0) {
                fetchOrganizerPlayerList(currentPage + 1);
            } else {
                setCurrentPage(0)
            }
        } else {
            fetchCustomerPlayerList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, watchEventType, watchKeyword, selectedGender, user])

    /**
        * @function handleGender
        * @params gender
        * @description set the value of gender
    */
    const handleGender = (gender) => {
        setSelectedGender(gender)
    }

    /**
        * @function handleSingleReceipt
        * @params customerId, receipt_no
        * @description get the value of receipt
    */
    const handleSingleReceipt = async (customerId, receipt_no) => {
        try {
            const result = await singleReceiptOrganizerService({
                customerId: customerId || 0,
                tournamentId
            });
            if (result?.data?.status) {
                setReceiptInfo(result?.data?.data?.find(item => item?.players?.length > 0 && item?.players[0]?.receipt_no === receipt_no))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function replaceSvgWithBase64
        * @params
        * @description used to convert svg into img
    */
    const replaceSvgWithBase64 = async () => {
        const svgs = pdfRef.current.querySelectorAll('svg');

        // Use Promise.all to wait for all SVG replacements
        await Promise.all(
            Array.from(svgs).map((svg) => {
                return new Promise((resolve) => {
                    const svgString = new XMLSerializer().serializeToString(svg);
                    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
                    const url = URL.createObjectURL(svgBlob);
                    const img = new Image();

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = svg.clientWidth;
                        canvas.height = svg.clientHeight;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);
                        const base64Image = canvas.toDataURL('image/png');

                        // Replace the SVG element with an image
                        const imgElement = document.createElement('img');
                        imgElement.src = base64Image;
                        imgElement.style.width = `${svg.clientWidth}px`;
                        imgElement.style.height = `${svg.clientHeight}px`;

                        // Check if the SVG has a class attribute and copy it
                        if (svg.getAttribute('class')) {
                            imgElement.setAttribute('class', svg.getAttribute('class'));
                        }

                        svg.parentNode.replaceChild(imgElement, svg);
                        resolve();
                    };

                    img.src = url;
                });
            })
        );
    };

    /**
        * @function handleDownload
        * @params
        * @description used to download receipt
    */
    const handleDownload = async () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            dispatch(setPdfLoading(true))
            setPdfContentShow(true)
        }, 1000);
        setTimeout(async () => {
            await replaceSvgWithBase64();
            const content = pdfRef.current;
            const doc = new jsPDF({
                orientation: 'p',
                unit: 'px',
                putOnlyUsedFonts: true
            })
            doc.internal.pageSize.width = content?.offsetWidth;
            doc.internal.pageSize.height = content?.offsetHeight + 10;
            doc.addFileToVFS('Poppins-Regular.ttf', PoppinsRegular)
            doc.addFileToVFS('Poppins-Medium.ttf', PoppinsMedium)
            doc.addFileToVFS('Poppins-Bold.ttf', PoppinsBold)
            doc.addFont('Poppins-Regular.ttf', 'Poppins', 'normal')
            doc.addFont('Poppins-Medium.ttf', 'Poppins', 'medium')
            doc.addFont('Poppins-Bold.ttf', 'Poppins', 'bold')
            doc.setFont('Poppins')
            doc.html(content, {
                callback: async (d) => {
                    const res = await d.save(`${props?.tournament?.name} reciept`)
                    dispatch(setPdfLoading(false))
                    setPdfContentShow(false)
                    return res
                },
                html2canvas: {
                    scale: 1,
                    allowTaint: true
                }
            })
        }, 2000);
    }

    /**
        * @function handleEditPlayerModal
        * @params row
        * @description set the visibility of edit player modal
    */
    const handleEditPlayerModal = (row) => {
        setEditPlayerModal(!editPlayerModal)
        setSelectedPlayer(row)
    }

    /**
        * @function handleAddPlayerModal
        * @params row
        * @description set the visibility of add player modal
    */
    const handleAddPlayerModal = (row) => {
        setAddPlayerModal(!addPlayerModal)
    }

    /**
        * @function fetchOrganizerPlayerList
        * @params page
        * @description fetch the list of player for organizer
    */
    const fetchOrganizerPlayerList = async (page) => {
        try {
            const result = await allPlayerOrganizerService({
                id: tournamentId,
                gender: selectedGender,
                event_type: watchEventType,
                paginate: 1,
                page,
                perPage: 10,
                keyword: watchKeyword
            });
            if (result?.data?.status) {
                setPlayerList(result?.data?.data?.player_lists)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchAllOrganizerPlayerList
        * @params
        * @description fetch the list of all player for organizer
    */
    const fetchAllOrganizerPlayerList = async () => {
        try {
            const result = await allPlayerOrganizerService({
                id: tournamentId,
                gender: '',
                event_type: '',
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                if (result?.data?.data?.player_lists?.length > 0) {
                    setCSVData(result?.data?.data?.player_lists?.map(item => {
                        return {
                            user_name: item?.customer?.username || '-',
                            player_name: item?.player_name || '-',
                            club_name: item?.club_name || '-',
                            team_id: item?.team_id || '-',
                            ...(props?.tournament?.sports_type === "PICKLEBALL" && {
                                dupr_rating: item?.dupr_rating || '-'
                            }),
                            player_email: item?.player_email || '-',
                            player_contact: item?.player_contact ? `=""${item?.player_contact}""` : '-',
                            event_type: (item?.event_type && `${props?.tournament?.category_type === "INDIVIDUAL" ? 'Individual - ' : 'Team - '}${props?.tournament?.category_type === "INDIVIDUAL" ? `(${convertEventType(item?.event_type)})(${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})` : `${item?.team_event_type?.map((teamItem, teamIndex) => {
                                return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                            })?.join(", ")}`}`) || '-',
                            player_gender: item?.player_gender ? convertGender(item?.player_gender) : '-',
                            player_dob: `${(item?.player_dob && moment().diff(item?.player_dob, 'years'))}yrs` || '-',
                            player_idcard: item?.player_idcard || '-',
                            nationality: item?.nationality || '-',
                            t_shirt_name: item?.tshirt_details?.tshirt?.tshirt_name || '-',
                            t_shirt_size: item?.tshirt_details?.size?.size || '-',
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            player_status: item?.player_status ? (item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status : '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchCustomerPlayerList
        * @params
        * @description fetch the list of player for customer
    */
    const fetchCustomerPlayerList = async () => {
        try {
            const result = await allPlayerCustomerService({
                id: tournamentId,
                paginate: 1,
                page: currentPage + 1,
                perPage: 10,
                keyword: ''
            });
            if (result?.data?.status) {
                setPlayerList(result?.data?.data?.player_list?.data)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchAllCustomerPlayerList
        * @params
        * @description fetch the list of all player for customer
    */
    const fetchAllCustomerPlayerList = async () => {
        try {
            const result = await allPlayerCustomerService({
                id: tournamentId,
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                if (result?.data?.data?.player_list?.data?.length > 0) {
                    setCSVData(result?.data?.data?.player_list?.data?.map(item => {
                        return {
                            user_name: item?.customer?.username || '-',
                            player_name: item?.player_name || '-',
                            club_name: item?.club_name || '-',
                            team_id: item?.team_id || '-',
                            ...(props?.tournament?.sports_type === "PICKLEBALL" && {
                                dupr_rating: item?.dupr_rating || '-'
                            }),
                            player_email: item?.player_email || '-',
                            player_contact: item?.player_contact ? `=""${item?.player_contact}""` : '-',
                            event_type: (item?.event_type && `${props?.tournament?.category_type === "INDIVIDUAL" ? 'Individual - ' : 'Team - '}${props?.tournament?.category_type === "INDIVIDUAL" ? `(${convertEventType(item?.event_type)})(${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})` : `${item?.team_event_type?.map((teamItem, teamIndex) => {
                                return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                            })?.join(", ")}`}`) || '-',
                            player_gender: item?.player_gender ? convertGender(item?.player_gender) : '-',
                            player_dob: `${(item?.player_dob && moment().diff(item?.player_dob, 'years'))}yrs` || '-',
                            player_idcard: item?.player_idcard || '-',
                            nationality: item?.nationality || '-',
                            t_shirt_name: item?.tshirt_details?.tshirt?.tshirt_name || '-',
                            t_shirt_size: item?.tshirt_details?.size?.size || '-',
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            player_status: item?.player_status ? (item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status : '-'
                        }
                    }))
                } else if (result?.data?.data?.player_list?.length > 0) {
                    setCSVData(result?.data?.data?.player_list?.map(item => {
                        return {
                            user_name: item?.customer?.username || '-',
                            player_name: item?.player_name || '-',
                            club_name: item?.club_name || '-',
                            team_id: item?.team_id || '-',
                            ...(props?.tournament?.sports_type === "PICKLEBALL" && {
                                dupr_rating: item?.dupr_rating || '-'
                            }),
                            player_email: item?.player_email || '-',
                            player_contact: item?.player_contact ? `=""${item?.player_contact}""` : '-',
                            event_type: (item?.event_type && `${props?.tournament?.category_type === "INDIVIDUAL" ? 'Individual - ' : 'Team - '}${props?.tournament?.category_type === "INDIVIDUAL" ? `(${convertEventType(item?.event_type)})(${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})` : `${item?.team_event_type?.map((teamItem, teamIndex) => {
                                return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                            })?.join(", ")}`}`) || '-',
                            player_gender: item?.player_gender ? convertGender(item?.player_gender) : '-',
                            player_dob: `${(item?.player_dob && moment().diff(item?.player_dob, 'years'))}yrs` || '-',
                            player_idcard: item?.player_idcard || '-',
                            nationality: item?.nationality || '-',
                            t_shirt_name: item?.tshirt_details?.tshirt?.tshirt_name || '-',
                            t_shirt_size: item?.tshirt_details?.size?.size || '-',
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            player_status: item?.player_status ? (item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status : '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function withdrawPlayer
        * @params playerId
        * @description used to withdraw a player
    */
    const withdrawPlayer = async (playerId) => {
        try {
            dispatch(setPdfLoading(true))
            const result = await withdrawPlayerService(playerId);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                await fetchOrganizerPlayerList(currentPage + 1);
                await fetchAllOrganizerPlayerList();
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch(setPdfLoading(false))
        } catch (error) {
            ErrorAlert(error)
            dispatch(setPdfLoading(false))
        }
    }

    /**
        * @function publishUnpublishPlayer
        * @params
        * @description used to publish/unpublish player list of a tournament
    */
    const publishUnpublishPlayer = async () => {
        try {
            setLoading(true)
            const result = await publishPlayerService(tournamentId);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchOrganizerSingleTournament()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    /**
        * @function CustomPagination
        * @params
        * @description used for Custom Pagination
    */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.player_name ? <span className='fs-14 fw-600 text-capitalize'>{row?.player_name}</span> : '-',
            sortable: true
        },
        {
            name: 'Club Name',
            selector: (row) => row?.club_name ? <span className='fs-14 fw-600 text-capitalize'>{row?.club_name}</span> : '-',
            sortable: true
        },
        {
            name: 'Team Id',
            selector: (row) => row?.team_id ? <span className='fs-14 text-secondary'>{row?.team_id}</span> : '-',
            sortable: true
        },
        {
            name: 'DUPR Rating',
            selector: (row) => row?.dupr_rating ? <span className='fs-14 text-secondary'>{row?.dupr_rating}</span> : '-',
            sortable: true,
            minWidth: '200px',
            omit: props?.tournament?.sports_type !== "PICKLEBALL"
        },
        {
            name: 'Email address',
            selector: (row) => row?.player_email ? <span className='fs-14 text-secondary'>{row?.player_email}</span> : '-',
            sortable: true,
            minWidth: '300px',
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && props?.tournament?.organization_id !== user?.id),
        },
        {
            name: 'Phone number',
            selector: (row) => row?.player_contact ? <span className='fs-14 text-secondary'>{row?.player_contact}</span> : '-',
            sortable: true,
            minWidth: '150px',
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && props?.tournament?.organization_id !== user?.id),
        },
        {
            name: 'Events',
            selector: (row) => row.event_type ? <div className='d-flex flex-column align-items-start fs-14 fw-400 text-secondary'>
                {(props?.tournament?.category_type === "INDIVIDUAL") ?
                    <span>Individual - </span>
                    :
                    <span>Team - </span>
                }
                {(row?.team_event_type) ?
                    <span>{row?.team_event_type?.map((teamItem, teamIndex) => {
                        return `(${convertEventType(teamItem)}${(row?.team_event_age_group?.length > 0 && row?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(row?.team_event_age_group[teamIndex], row?.team_event_age_value[teamIndex])})` : ""})`
                    })?.join(", ")}</span>
                    :
                    <span>
                        {row?.event_type && `(${convertEventType(row?.event_type)}${(row?.age_group && row?.age_value) ? ` (${convertAgeGroup(row?.age_group, row?.age_value)})` : ""})`}
                    </span>
                }
            </div> : '-',
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Gender',
            selector: (row) => row?.player_gender ? <span className='fs-14 text-secondary'>{convertGender(row?.player_gender)}</span> : '-',
            sortable: true
        },
        {
            name: 'Player Age',
            selector: (row) => row?.player_dob ? <span className='fs-14 text-secondary'>{`${(row?.player_dob && moment().diff(row?.player_dob, 'years'))}yrs`}</span> : '-',
            sortable: true,
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && props?.tournament?.organization_id !== user?.id),
        },
        {
            name: 'IC/Passport number',
            selector: (row) => row?.player_idcard ? <span className='fs-14 text-secondary'>{row?.player_idcard}</span> : '-',
            sortable: true,
            minWidth: '180px',
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && props?.tournament?.organization_id !== user?.id),
        },
        {
            name: 'Country',
            selector: (row) => row?.nationality ? <span className='fs-14 text-secondary text-capitalize'>{row?.nationality}</span> : '-',
            sortable: true
        },
        {
            name: 'User name',
            selector: (row) => row?.customer?.username ? <span className='fs-14 fw-600 text-capitalize'>{row?.customer?.username}</span> : '-',
            sortable: true,
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && props?.tournament?.organization_id !== user?.id),
            width: '200px'
        },
        {
            name: 'Phone no',
            selector: (row) => row?.customer?.phone ? <span className='fs-14 text-capitalize'>{row?.customer?.phone}</span> : '-',
            sortable: true,
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && props?.tournament?.organization_id !== user?.id),
            width: '200px'
        },
        {
            name: 'T-shirt name',
            selector: (row) => row?.tshirt_details ? <span className='fs-14 text-capitalize'>{row?.tshirt_details?.tshirt?.tshirt_name}</span> : '-',
            sortable: true,
            width: '200px',
            omit: user?.type !== 'organizer'
        },
        {
            name: 'T-shirt size',
            selector: (row) => row?.tshirt_details ? <span className='fs-14 text-capitalize'>{row?.tshirt_details?.size?.size}</span> : '-',
            sortable: true,
            width: '200px',
            omit: user?.type !== 'organizer'
        },
        {
            name: 'T-shirt name',
            selector: (row) => row?.tshirt_details?.length > 0 ? <span className='fs-14 text-capitalize'>{row?.tshirt_details[0]?.tshirt?.tshirt_name}</span> : '-',
            sortable: true,
            width: '200px',
            omit: user?.type === 'organizer'
        },
        {
            name: 'T-shirt size',
            selector: (row) => row?.tshirt_details?.length > 0 ? <span className='fs-14 text-capitalize'>{row?.tshirt_details[0]?.size}</span> : '-',
            sortable: true,
            width: '200px',
            omit: user?.type === 'organizer'
        },
        {
            name: 'Status',
            selector: (row) => row?.player_status ? <span className='fs-14 text-secondary text-capitalize'>{(row?.player_status === "0" || row?.player_status === "ACTIVE") ? 'ACTIVE' : row?.player_status}</span> : '-',
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && ((props?.tournament?.organization_id !== user?.id && !user?.parent_id) || (!(props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id)) && user?.parent_id)))
        },
        {
            name: 'Generate Receipts',
            selector: (row) => <GenerateReceipt role='button' onClick={() => handleSingleReceipt(row?.customer_id, row?.receipt_no)} />,
            sortable: true,
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && ((props?.tournament?.organization_id !== user?.id && !user?.parent_id) || (!(props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id)) && user?.parent_id)))
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <div className='d-flex align-items-center'>
                <Form.Check
                    type="switch"
                    checked={row?.player_status === "0" || row?.player_status === "ACTIVE"}
                    onChange={() => withdrawPlayer(row?.id)}
                />
                <FaRegEdit role='button' className='text-primary me-2' size={20} onClick={() => handleEditPlayerModal(row)} />
            </div>,
            omit: user?.type !== 'organizer' || (user?.type === 'organizer' && ((props?.tournament?.organization_id !== user?.id && !user?.parent_id) || (!(props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id)) && user?.parent_id)))
        }
    ]

    return <Row className='my-3 px-3 px-lg-0'>
        <div className='d-flex flex-column flex-lg-row align-items-start mb-3'>
            <div className='d-flex justify-content-between justify-content-lg-start align-items-center mb-lg-3 w-100'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <span className='fs-24 fw-600 me-2'>Players</span>
                    <span className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded me-5'>{csvData?.length || playerList?.length || 0} players</span>
                </div>
                {user?.type === 'organizer' &&
                    <div className='d-none d-lg-block me-2'>
                        <PrimaryButton className='fs-14 py-1' onClick={handleAddPlayerModal}>Add Player</PrimaryButton>
                    </div>
                }
                {user?.type === 'organizer' &&
                    <div className='d-none d-lg-block me-2'>
                        {!props?.tournament?.player_publish ?
                            <PrimaryButton className='fs-14 py-1' disabled={loading} loading={loading} onClick={publishUnpublishPlayer}>Publish Player List</PrimaryButton>
                            :
                            <PrimaryButton className='fs-14 py-1' disabled={loading} loading={loading} onClick={publishUnpublishPlayer}>Unpublish Player List</PrimaryButton>
                        }
                    </div>
                }
                {user?.type === 'organizer' &&
                    <CSVLink className="text-decoration-none" filename={`${props?.tournament?.name || "my"}-Players.csv`} data={csvData} headers={csvHeaders}>
                        <span role='button' className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded d-none d-lg-block'>Export CSV</span>
                    </CSVLink>
                }
            </div>
            {user?.type === 'organizer' &&
                <>
                    <div className='d-flex flex-wrap justify-content-between align-items-center d-lg-none mb-3 w-100'>
                        <div className='mt-3 me-2'>
                            <PrimaryButton className='fs-14 py-1' onClick={handleAddPlayerModal}>Add Player</PrimaryButton>
                        </div>
                        <div className='mt-3 me-2'>
                            {!props?.tournament?.player_publish ?
                                <PrimaryButton className='fs-14 py-1' disabled={loading} loading={loading} onClick={publishUnpublishPlayer}>Publish Player List</PrimaryButton>
                                :
                                <PrimaryButton className='fs-14 py-1' disabled={loading} loading={loading} onClick={publishUnpublishPlayer}>Unpublish Player List</PrimaryButton>
                            }
                        </div>
                        <CSVLink className="mt-3 text-decoration-none" filename={`${props?.tournament?.name || "my"}-players.csv`} data={csvData} headers={csvHeaders}>
                            <span role='button' className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                        </CSVLink>
                    </div>
                </>
            }
        </div>
        {user?.type === 'organizer' &&
            <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center flex-grow-1 mb-3'>
                <div className='d-flex flex-column flex-md-row align-items-md-center flex-grow-1 mb-3 mb-md-0'>
                    <div className='d-flex align-items-center fs-14 text-secondary mb-3 mb-md-0 me-3'>
                        <span role='button' className={classNames('me-2', {
                            "text-primary fw-600": selectedGender === ''
                        })} onClick={() => handleGender('')}>All</span>
                        <span role='button' className={classNames('me-2', {
                            "text-primary fw-600": selectedGender === 'MALE'
                        })} onClick={() => handleGender('MALE')}>Male</span>
                        <span role='button' className={classNames({
                            "text-primary fw-600": selectedGender === 'FEMALE'
                        })} onClick={() => handleGender('FEMALE')}>Female</span>
                    </div>
                    <Form.Group className='me-md-3'>
                        <Form.Select
                            {...register("event_type")}
                        >
                            <option value="">Events</option>
                            {props?.eventList?.length > 0 && props?.eventList?.map((item, index) => {
                                return <option key={`event${index}`} value={item?.value}>{item?.label}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className='w-xs-100 tournament-input'>
                    <InputGroup className="border rounded-3">
                        <InputGroup.Text className='bg-transparent border-0'>
                            <FiSearch />
                        </InputGroup.Text>
                        <Form.Control
                            className='bg-transparent border-0 rounded-3 px-0 search-input'
                            placeholder='Search by Player name, Phone number or TP_ID,'
                            {...register("keyword")}
                            onChange={debounce((e) => {
                                setValue('keyword', e.target.value)
                            }, 500)}
                        />
                    </InputGroup>
                </div>
            </div>
        }
        {playerList?.length > 0 ?
            <>
                <DataTable
                    className='d-none d-lg-block'
                    columns={columns}
                    data={playerList}
                    pagination
                    paginationServer
                    paginationTotalRows={10}
                    paginationPerPage={10}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationComponent={CustomPagination}
                    paginationDefaultPage={currentPage + 1}
                />
                <div>
                    {playerList?.map((item, index) => {
                        return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={index}>
                            <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                                <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.player_name}</span>
                                {user?.type === 'organizer' && (props?.tournament?.organization_id === user?.id || (props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                                    <div className='d-flex align-items-center'>
                                        <Form.Check
                                            type="switch"
                                            checked={item?.player_status === "0" || item?.player_status === "ACTIVE"}
                                            onChange={() => withdrawPlayer(item?.id)}
                                        />
                                        <FaRegEdit role='button' className='text-primary me-2' size={20} onClick={() => handleEditPlayerModal(item)} />
                                        <GenerateReceipt role='button' onClick={() => handleSingleReceipt(item?.customer_id, item?.receipt_no)} />
                                    </div>
                                }
                            </div>
                            <div className='d-flex flex-column align-items-start w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary mb-1'>Team Id: {item?.team_id || "-"}</span>
                                <span className='fs-14 fw-400 text-secondary mb-1'>Club Name: {item?.club_name || "-"}</span>
                                {user?.type === 'organizer' && (props?.tournament?.organization_id === user?.id || (props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                                    <>
                                        {item?.player_status ? <span className='fs-14 text-secondary text-capitalize'>Status: {(item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status}</span> : 'Status: -'}
                                    </>
                                }
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>{item?.player_gender ? convertGender(item?.player_gender) : '-'}</span>
                                {user?.type === 'organizer' && props?.tournament?.organization_id === user?.id && <span className='fs-14 fw-400 text-secondary'>Age {`${(item?.player_dob && moment().diff(item?.player_dob, 'years'))}yrs`}</span>}
                            </div>
                            {user?.type === 'organizer' && props?.tournament?.organization_id === user?.id &&
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-4'>{item?.player_email}</span>
                                    <span className='fs-14 fw-400 text-secondary'>{item?.player_contact}</span>
                                </div>
                            }
                            <div className='w-100 mt-1 fs-14 fw-400 text-secondary'>
                                {(item?.team_event_type) ?
                                    <span>Team - </span>
                                    :
                                    <span>Individual - </span>
                                }
                                {(item?.team_event_type) ?
                                    <span>{item?.team_event_type?.map((teamItem, teamIndex) => {
                                        return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                                    })?.join(", ")}</span>
                                    :
                                    <span>
                                        {item?.event_type && `(${convertEventType(item?.event_type)}${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})`}
                                    </span>
                                }
                            </div>
                            {user?.type === 'organizer' && props?.tournament?.organization_id === user?.id &&
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-4'>IC/Passport number : {item?.player_idcard}</span>
                                </div>
                            }
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary text-capitalize'>{item?.nationality}</span>
                            </div>
                            {user?.type === 'organizer' && props?.tournament?.organization_id === user?.id &&
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary text-capitalize'>User name: {item?.customer?.username}</span>
                                </div>
                            }
                            {user?.type === 'organizer' && props?.tournament?.organization_id === user?.id &&
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary text-capitalize'>Phone no: {item?.customer?.phone}</span>
                                </div>
                            }
                            {item?.tshirt_details && user?.type === 'organizer' &&
                                <>
                                    <div className='d-flex align-items-center w-100 mt-1'>
                                        <span className='fs-14 fw-400 text-secondary text-capitalize'>T-shirt name: {item?.tshirt_details?.tshirt?.tshirt_name}</span>
                                    </div>
                                    <div className='d-flex align-items-center w-100 mt-1'>
                                        <span className='fs-14 fw-400 text-secondary text-capitalize'>T-shirt size: {item?.tshirt_details?.size?.size}</span>
                                    </div>
                                </>
                            }
                            {item?.tshirt_details?.length > 0 && (user?.type !== 'organizer' || !user) &&
                                <>
                                    <div className='d-flex align-items-center w-100 mt-1'>
                                        <span className='fs-14 fw-400 text-secondary text-capitalize'>T-shirt name: {item?.tshirt_details[0]?.tshirt?.tshirt_name}</span>
                                    </div>
                                    <div className='d-flex align-items-center w-100 mt-1'>
                                        <span className='fs-14 fw-400 text-secondary text-capitalize'>T-shirt size: {item?.tshirt_details[0]?.size}</span>
                                    </div>
                                </>
                            }
                            {props?.tournament?.sports_type === "PICKLEBALL" &&
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary text-capitalize'>DUPR Rating : {item?.dupr_rating || "-"}</span>
                                </div>
                            }
                        </div>
                    })}
                    <ReactPaginate
                        nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <span className='fs-14 fw-600 me-2'>Next</span>
                            <AiOutlineArrowRight className='text-secondary' size={20} />
                        </div>}
                        breakLabel='...'
                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                            <span className='fs-14 fw-600'>Previous</span>
                        </div>}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        marginPagesDisplayed={3}
                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                        breakClassName='page-item me-3 d-flex align-items-center'
                        nextClassName='page-item next-item flex-grow-1 text-end'
                        previousClassName='page-item prev-item flex-grow-1 me-3'
                        disabledLinkClassName='btn disabled p-0 border-0'
                        pageCount={totalEntry || 1}
                        onPageChange={page => handlePagination(page)}
                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                    />
                    <Receipt pdfRef={pdfRef} pdfContentShow={pdfContentShow} tournament={receiptInfo} />
                </div>
            </>
            :
            <NoPlayer />
        }

        {/* Add Player Modal */}
        <AddPlayerModal
            show={addPlayerModal}
            handleClose={handleAddPlayerModal}
            tournament={props?.tournament}
            fetchOrganizerPlayerList={fetchOrganizerPlayerList}
            fetchAllOrganizerPlayerList={fetchAllOrganizerPlayerList}
            setCurrentPage={setCurrentPage}
        />

        {/* Edit Player Modal */}
        <EditPlayerModal
            show={editPlayerModal}
            handleClose={handleEditPlayerModal}
            selectedData={selectedPlayer}
            tournament={props?.tournament}
            fetchOrganizerPlayerList={fetchOrganizerPlayerList}
            currentPage={currentPage}
        />
    </Row>
};

export default MyPlayer;