import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

const ViewTournamentModal = (props) => {
    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.name ? <Link className="text-decoration-none" to={`/tournament/${row?.id}`}>
                <span className='fs-14 fw-600 text-capitalize'>{row?.name}</span>
            </Link> : '-',
            sortable: true
        }
    ]

    /**
        * @function handleClose
        * @params
        * @description used to close view tournament modal
    */
    const handleClose = async () => {
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleClose} centered size='lg'>
        <Modal.Header className='fs-24 fw-600 border-0' closeButton>
            View Tournament
        </Modal.Header>
        <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
            <DataTable
                columns={columns}
                data={props?.selectedTournamentList}
            />
        </Modal.Body>
    </Modal>
};

export default ViewTournamentModal;