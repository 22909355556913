import { useState, useEffect } from 'react'
import { Row, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineEye } from "react-icons/ai";
import { PiUserCircleLight } from "react-icons/pi";
import { FiEdit3 } from "react-icons/fi";

// Custom Component
import { PrimaryButton } from "../Button";
import SearchInput from "../SearchInput/SearchInput";
import NoSubOrganizer from "./NoSubOrganizer";
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';
import AddSubOrganizerModal from "../Modal/AddSubOrganizerModal";
import EditSubOrganizerModal from "../Modal/EditSubOrganizerModal";
import ViewSubOrganizerModal from "../Modal/ViewSubOrganizerModal";

// Utils
import { convertGender } from '../../utils/helper';

// API Service
import { allSubOrganizerService, updateSubOrganizerService } from '../../service/subOrganizer.service';

const MySubOrganizer = (props) => {
    const [subOrganizerList, setSubOrganizerList] = useState([])
    const [selectedItem, setSelectedItem] = useState()
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [searchTag, setSearchTag] = useState('')
    const [csvData, setCSVData] = useState([]);
    const [addSubOrganizerModal, setAddSubOrganizerModal] = useState(false)
    const [editSubOrganizerModal, setEditSubOrganizerModal] = useState(false)
    const [viewSubOrganizerModal, setViewSubOrganizerModal] = useState(false)

    const csvHeaders = [
        {
            label: 'Name',
            key: 'name'
        },
        {
            label: 'Email address',
            key: 'email'
        },
        {
            label: 'Phone number',
            key: 'phone'
        },
        {
            label: 'Gender',
            key: 'gender'
        }
    ];

    /**
        * @function fetchSubOrganizerList
        * @params page
        * @description fetch the list of sub organizer
    */
    const fetchSubOrganizerList = async (page) => {
        try {
            const result = await allSubOrganizerService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: searchTag,
                status: null
            });
            if (result?.data?.status) {
                setSubOrganizerList(result?.data?.data?.organizations)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
                if (result?.data?.data?.organizations?.length > 0) {
                    setCSVData(result?.data?.data?.organizations?.map(item => {
                        return {
                            name: item?.name || '-',
                            email: item?.email || '-',
                            phone: item?.phone ? `=""${item?.phone}""` : '-',
                            gender: item?.gender ? convertGender(item?.gender) : '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchSubOrganizerList(currentPage)
        // eslint-disable-next-line
    }, [searchTag])

    /**
        * @function statusUpdate
        * @params rowRecord
        * @description used to change status
    */
    const statusUpdate = async (rowRecord) => {
        try {
            const data = new FormData()
            data.append('_method', 'PUT')
            data.append('name', rowRecord?.name)
            data.append('phone', rowRecord?.phone)
            data.append('status', rowRecord?.status ? 0 : 1)
            const result = await updateSubOrganizerService(rowRecord?.id, data);
            if (result?.data?.status) {
                SuccessAlert('Status updated successfully')
                fetchSubOrganizerList(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }

        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handleAddSubOrganizerModal
        * @params
        * @description set the visibility of add sub organizer modal
    */
    const handleAddSubOrganizerModal = () => {
        setAddSubOrganizerModal(!addSubOrganizerModal)
    }

    /**
        * @function handleEditSubOrganizerModal
        * @params item
        * @description set the visibility of edit sub organizer modal
    */
    const handleEditSubOrganizerModal = (item) => {
        setSelectedItem(item)
        setEditSubOrganizerModal(!editSubOrganizerModal)
    }

    /**
        * @function handleViewSubOrganizerModal
        * @params item
        * @description set the visibility of view sub organizer modal
    */
    const handleViewSubOrganizerModal = (item) => {
        setSelectedItem(item)
        setViewSubOrganizerModal(!viewSubOrganizerModal)
    }

    const columns = [
        {
            name: 'Image',
            selector: (row) => row?.profile_image ? <img className='profile-small-photo rounded-circle' src={row?.profile_image} alt={row?.name} /> : <PiUserCircleLight className="text-secondary rounded-circle" size={32} />,
            sortable: true,
            maxWidth: "40px"
        },
        {
            name: 'Name',
            selector: (row) => row?.name ? <span className='fs-14 fw-600 text-capitalize'>{row?.name}</span> : '-',
            sortable: true
        },
        {
            name: 'Email address',
            selector: (row) => row?.email ? <span className='fs-14 text-secondary'>{row?.email}</span> : '-',
            sortable: true
        },
        {
            name: 'Phone number',
            selector: (row) => row?.phone ? <span className='fs-14 text-secondary'>{row?.phone}</span> : '-',
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row?.gender ? <span className='fs-14 text-secondary'>{convertGender(row?.gender)}</span> : '-',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    checked={row?.status}
                    onChange={() => statusUpdate(row)}
                    defaultChecked={row?.status}
                />
            ),
            width: '100px'
        },
        {
            name: 'Actions',
            selector: (row) => <>
                <AiOutlineEye role='button' className='text-primary me-2' size={20} onClick={() => handleViewSubOrganizerModal(row)} />
                <FiEdit3 role='button' className='text-secondary' size={20} onClick={() => handleEditSubOrganizerModal(row)} />
            </>,
            width: '100px'
        }
    ]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchSubOrganizerList(page.selected)
    }

    /**
        * @function CustomPagination
        * @params
        * @description used for Custom Pagination
    */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    /**
        * @function searchSubOrganizer
        * @params searchTerm
        * @description used to set the value of search tag
    */
    const searchSubOrganizer = (searchTerm) => {
        setSearchTag(searchTerm)
    }

    return <Row className='my-3'>
        {subOrganizerList.length > 0 ?
            <>
                <div className='d-flex flex-column flex-lg-row align-items-start mb-3'>
                    <div className='d-flex justify-content-between justify-content-lg-start align-items-center mb-3 w-100'>
                        <div className='d-flex align-items-center flex-grow-1'>
                            <span className='fs-24 fw-600 me-2'>Sub-Organizer</span>
                            <span className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded me-5'>{csvData?.length || subOrganizerList?.length || 0}</span>
                        </div>
                        <div className='d-none d-lg-block me-2'>
                            <PrimaryButton onClick={handleAddSubOrganizerModal}>+ Add Sub-Organizer</PrimaryButton>
                        </div>
                        <div className='d-none d-lg-block me-2'>
                            <SearchInput placeholder='Search Sub-Organizer' search={searchSubOrganizer} />
                        </div>
                        <CSVLink className="text-decoration-none" filename={`Sub-Organizer.csv`} data={csvData} headers={csvHeaders}>
                            <span role='button' className='fs-14 fw-600 text-primary p-2 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                        </CSVLink>
                    </div>
                    <div className='d-flex d-lg-none align-items-center mb-3 w-100'>
                        <div className='me-2'>
                            <PrimaryButton onClick={handleAddSubOrganizerModal}>+ Add</PrimaryButton>
                        </div>
                        <div className='flex-grow-1'>
                            <SearchInput placeholder='Search Sub-Organizer' search={searchSubOrganizer} />
                        </div>
                    </div>
                </div>
                <DataTable
                    className='d-none d-lg-block'
                    columns={columns}
                    data={subOrganizerList}
                    pagination
                    paginationServer
                    paginationTotalRows={10}
                    paginationPerPage={10}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationComponent={CustomPagination}
                    paginationDefaultPage={currentPage + 1}
                />
                <div>
                    {subOrganizerList?.map((item, index) => {
                        return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={index}>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='d-flex align-items-center'>
                                    {item?.profile_image ? <img className='profile-small-photo rounded-circle me-1' src={item?.profile_image} alt={item?.name} /> : <PiUserCircleLight className="text-secondary rounded-circle me-1" size={32} />}
                                    <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.name}</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Form.Check
                                        type="switch"
                                        checked={item?.status}
                                        onChange={() => statusUpdate(item)}
                                        defaultChecked={item?.status}
                                    />
                                    <FiEdit3 role='button' className='text-secondary' size={20} onClick={() => {
                                        handleEditSubOrganizerModal(item)
                                    }} />
                                </div>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>{item?.email}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>+60 {item?.phone}</span>
                            </div>
                            {item?.gender &&
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-4'>{convertGender(item?.gender)}</span>
                                </div>
                            }
                        </div>
                    })}
                    <ReactPaginate
                        nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <span className='fs-14 fw-600 me-2'>Next</span>
                            <AiOutlineArrowRight className='text-secondary' size={20} />
                        </div>}
                        breakLabel='...'
                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                            <span className='fs-14 fw-600'>Previous</span>
                        </div>}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        marginPagesDisplayed={3}
                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                        breakClassName='page-item me-3 d-flex align-items-center'
                        nextClassName='page-item next-item flex-grow-1 text-end'
                        previousClassName='page-item prev-item flex-grow-1 me-3'
                        disabledLinkClassName='btn disabled p-0 border-0'
                        pageCount={totalEntry || 1}
                        onPageChange={page => handlePagination(page)}
                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                    />
                </div>
            </>
            :
            <div className='d-flex flex-column align-items-center'>
                <NoSubOrganizer />
                <div className='d-inline-block'>
                    <PrimaryButton onClick={handleAddSubOrganizerModal}>+ Add Sub-Organizer</PrimaryButton>
                </div>
            </div>
        }

        {/* Add Sub Organizer Modal */}
        <AddSubOrganizerModal
            show={addSubOrganizerModal}
            handleClose={handleAddSubOrganizerModal}
            fetchSubOrganizerList={fetchSubOrganizerList}
            size='lg'
        />

        {/* Edit Sub Organizer Modal */}
        <EditSubOrganizerModal
            show={editSubOrganizerModal}
            handleClose={handleEditSubOrganizerModal}
            fetchSubOrganizerList={fetchSubOrganizerList}
            selectedItem={selectedItem}
            size='lg'
        />

        {/* View Sub Organizer Modal */}
        <ViewSubOrganizerModal
            show={viewSubOrganizerModal}
            handleClose={handleViewSubOrganizerModal}
            fetchSubOrganizerList={fetchSubOrganizerList}
            selectedItem={selectedItem}
            size='lg'
        />
    </Row>
};

export default MySubOrganizer;