import { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert'

// API Service
import { allCountryService } from '../../service/country.service';

const ViewSubOrganizerModal = (props) => {
    const [subOrganizerImage, setSubOrganizerImage] = useState(null);
    const [countryList, setCountryList] = useState([])

    const { register, setValue, reset } = useForm({
        mode: "all"
    });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
                if (result?.data?.data?.length > 0) {
                    setValue("country_id", result?.data?.data[0]?.id)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // for setting default values
    useEffect(() => {
        if (props?.selectedItem) {
            let defaultValues = {};
            defaultValues.name = props?.selectedItem?.name
            defaultValues.email = props?.selectedItem?.email
            defaultValues.phone = props?.selectedItem?.phone
            defaultValues.country_id = props?.selectedItem?.country_id
            defaultValues.address = props?.selectedItem?.address
            defaultValues.gender = props?.selectedItem?.gender
            defaultValues.password = props?.selectedItem?.password
            defaultValues.password_confirmation = props?.selectedItem?.password_confirmation
            if (props?.selectedItem?.profile_image) {
                setSubOrganizerImage(props?.selectedItem?.profile_image)
            }
            reset({ ...defaultValues })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    /**
        * @function handleClose
        * @params
        * @description used to close view sub organizer modal
    */
    const handleClose = async () => {
        reset()
        setSubOrganizerImage()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleClose} centered size='lg'>
        <Modal.Header className='fs-24 fw-600 border-0' closeButton>
            View Sub-Organizer
        </Modal.Header>
        <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
            <div className='w-100'>
                <Row>
                    <Col lg={4}>
                        {subOrganizerImage &&
                            <>
                                <p>Profile Image</p>
                                <img role='button' className='w-100' src={subOrganizerImage} alt={props?.selectedItem?.name} />
                            </>
                        }
                    </Col>
                </Row>
                <Form>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name here"
                            disabled
                            {...register("name")}
                        />
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            disabled
                            {...register("email")}
                        />
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Phone number</Form.Label>
                        <InputGroup className='rounded'>
                            <InputGroup.Text className='p-0 border-0'>
                                <Form.Select
                                    className='border-end-0'
                                    disabled
                                    {...register("country_id")}
                                >
                                    {countryList.map((item, index) => {
                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                    })}
                                </Form.Select>
                            </InputGroup.Text>
                            <Form.Control
                                className='border-start-0 ps-0'
                                type="tel"
                                disabled
                                placeholder="Enter Phone number"
                                {...register("phone")}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Address</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            disabled
                            placeholder="Enter Address"
                            {...register("address")}
                        />
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Gender</Form.Label>
                        <Form.Select
                            disabled
                            {...register("gender")}
                        >
                            <option value='MALE'>Male</option>
                            <option value='FEMALE'>Female</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
};

export default ViewSubOrganizerModal;