import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button'
import AuthFooter from '../../components/Common/AuthFooter'
import ErrorAlert from '../../components/Alert/ErrorAlert';
import OTPVerificationModal from '../../components/Modal/OTPVerificationModal';

// Images
import { logo, authentication } from '../../assets/images'

// API Service
import { forgetPasswordService } from '../../service/auth.service';
import { allCountryService } from '../../service/country.service';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([])
    const [otpVerificationModal, setOTPVerificationModal] = useState(false)

    const { register, watch, setValue, getValues, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });
    const watchCountryId = watch("country_id")

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
                if (result?.data?.data?.length > 0) {
                    setValue("country_id", result?.data?.data[0]?.id)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function handleOTPVerificationModal
        * @params
        * @description set the visibility of otp verification modal
    */
    const handleOTPVerificationModal = () => {
        setOTPVerificationModal(!otpVerificationModal)
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to send otp to phone number for reset password
    */
    const onSubmit = async (formdata) => {
        try {
            setLoading(true)
            const result = await forgetPasswordService({
                phone: formdata.phone
            });
            if (result?.data?.status) {
                handleOTPVerificationModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Forgot Password | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='pe-lg-0'>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" />
                    </Link>
                    <Row className="justify-content-center align-items-center flex-grow-1 w-100 mt-5">
                        <Col xs={12} sm={8}>
                            <p className='fs-30 fw-bold'>Forgot Password</p>
                            <span className='fw-400 text-secondary'>Enter registered Phone number to get verification code</span>

                            {countryList?.length > 0 &&
                                <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <InputGroup.Text className='p-0 border-0'>
                                                <Form.Select
                                                    className='border-end-0'
                                                    {...register("country_id")}
                                                >
                                                    {countryList.map((item, index) => {
                                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                                    })}
                                                </Form.Select>
                                            </InputGroup.Text>
                                            <Form.Control
                                                className='border-start-0 ps-0'
                                                type="tel"
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Phone is required"
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>

                                    <Form.Group className="mt-5 mb-3">
                                        <PrimaryButton disabled={loading} loading={loading} type="submit">Get Verification code</PrimaryButton>
                                    </Form.Group>
                                </Form>
                            }

                            <div className='text-center mt-5 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Remember Password?
                                    <Link to='/login' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Sign In</span>
                                    </Link>
                                </span>
                            </div>
                        </Col>
                        <AuthFooter />
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>

        {/* OTP Verification Modal */}
        <OTPVerificationModal
            show={otpVerificationModal}
            handleClose={handleOTPVerificationModal}
            size='sm'
            country={countryList.find(item => item.id === parseInt(watchCountryId))?.code}
            phone={getValues("phone")}
            forgotPassword={true}
        />
    </HelmetProvider>
};

export default ForgotPassword;