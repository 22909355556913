// Images
import { Men, Women } from "../assets/images/svg";

/**
    * @function convertEventType
    * @params event
    * @description used to convert event
*/
export const convertEventType = (event) => {
    if (event) {
        switch (event) {
            case 'MEN_SINGLE':
                return 'Men Single'
            case 'WOMEN_SINGLE':
                return 'Women Single'
            case 'MEN_DOUBLE':
                return 'Men Double'
            case 'WOMEN_DOUBLE':
                return 'Women Double'
            case 'MIX_DOUBLE':
                return 'Mix Double'
            case 'MEN_3_3':
                return 'Men 3 on 3'
            case 'WOMEN_3_3':
                return 'Women 3 on 3'
            case 'MIX_3_3':
                return 'Mix 3 on 3'
            case 'JUNIOR_SINGLE':
                return 'Group Captain'
            case 'GROUP_CAPTAIN':
                return 'Group Captain'
            case 'JUNIOR_DOUBLE':
                return 'Junior Double'
            case 'GROUP_7':
                return 'Group 7pax'
            default:
                return ''
        }
    } else {
        return ''
    }
}

/**
    * @function convertAgeGroup
    * @params ageGroup, ageValue
    * @description used to convert age group
*/
export const convertAgeGroup = (ageGroup, ageValue) => {
    if (ageGroup) {
        switch (ageGroup) {
            case 'BELOW_AGE':
                return `Below ${parseInt(ageValue)}`
            case 'ABOVE_AGE':
                return `Above ${parseInt(ageValue)}`
            case 'TOTAL_BELOW_AGE':
                return `Total below ${parseInt(ageValue)}`
            case 'TOTAL_ABOVE_AGE':
                return `Total above ${parseInt(ageValue)}`
            case 'OPEN':
                return 'Open'
            case 'AMATEUR':
                return 'Amateur'
            case 'BAKAT_BARU':
                return 'Bakat Baru'
            case 'SEMI_PRO':
                return 'Semi-Pro'
            default:
                return ''
        }
    } else {
        return ''
    }
}

/**
    * @function convertGender
    * @params gender
    * @description used to convert gender
*/
export const convertGender = (gender) => {
    if (gender) {
        switch (gender) {
            case 'MALE':
                return 'Male'
            case 'FEMALE':
                return 'Female'
            default:
                return ''
        }
    } else {
        return ''
    }
}

/**
    * @function detectMenEvent
    * @params { event, color, className }
    * @description used to detect men event
*/
export const detectMenEvent = ({ event, color, className }) => {
    if (event === 'MEN_SINGLE' || event === 'MEN_DOUBLE' || event === 'MIX_DOUBLE' || event === 'MEN_3_3' || event === 'MIX_3_3' || event === 'JUNIOR_SINGLE' || event === 'GROUP_CAPTAIN' || event === 'JUNIOR_DOUBLE' || event === 'GROUP_7') {
        return <Men color={color} className={className} />
    }
}

/**
    * @function detectWomenEvent
    * @params { event, color, className }
    * @description used to detect women event
*/
export const detectWomenEvent = ({ event, color, className }) => {
    if (event === 'WOMEN_SINGLE' || event === 'WOMEN_DOUBLE' || event === 'MIX_DOUBLE' || event === 'WOMEN_3_3' || event === 'MIX_3_3' || event === 'JUNIOR_SINGLE' || event === 'GROUP_CAPTAIN' || event === 'JUNIOR_DOUBLE' || event === 'GROUP_7') {
        return <Women color={color} className={className} />
    }
}