import { useState } from 'react';
import { useSelector } from 'react-redux';

// Custom Component
import { PrimaryOutlineButton } from "../Button";
import CreateTournamentModal from "../Modal/Tournament/CreateTournamentModal";

// Images
import { trophy, smallTrophy } from "../../assets/images";

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const NoTournament = (props) => {
    const [createTournamentModal, setCreateTournamentModal] = useState(false)

    const user = useSelector(selectUser);

    /**
        * @function handleCreateTournamentModal
        * @params
        * @description set the visibility of create tournament modal
    */
    const handleCreateTournamentModal = () => {
        setCreateTournamentModal(!createTournamentModal)
    }

    return <div className='my-5 text-center'>
        <div className="d-none d-md-inline-block">
            <img className='img-fluid mt-5 mb-3' src={trophy} alt='trophy' />
        </div>
        <div className="d-md-none">
            <img className='img-fluid mt-5 mb-3' src={smallTrophy} alt='trophy' />
        </div>
        <p className='fs-25 fw-500 text-secondary d-none d-md-block'>There are no tournaments, Create a new tournaments</p>
        <p className='fs-16 fw-500 text-secondary d-md-none'>There are no tournaments, Create a new tournaments</p>
        {user?.type === 'organizer' && !props?.search && !user?.parent_id &&
            <div className='d-inline-block mt-3'>
                <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={handleCreateTournamentModal}>Create Tournament</PrimaryOutlineButton>
            </div>
        }

        {/* Create Tournament Modal */}
        <CreateTournamentModal
            show={createTournamentModal}
            handleClose={handleCreateTournamentModal}
            fetchTournamentList={props?.fetchTournamentList}
            size='lg'
        />
    </div>
};

export default NoTournament;