import { Form } from 'react-bootstrap';
import { useFieldArray, Controller } from "react-hook-form";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";

// Custom Component
import ErrorText from '../../TextField/ErrorText'

const AgeGroup = ({ nestIndex, allWatchedValues, control, register, errors, tournament }) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `event_details[${nestIndex}].age_groups`
    });

    return <div>
        {fields?.length > 0 && fields.map((item, index) => {
            return <div>
                <div className='d-flex flex-column align-items-start mt-4 position-relative' key={item.id}>
                    <div className='d-flex align-items-start w-100 pe-4'>
                        <div className='d-flex flex-column align-items-start flex-grow-1'>
                            <div className={classNames("w-100 d-flex flex-column flex-sm-row align-items-start pe-2", {
                                'mb-3': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7"
                            })}>
                                <div className={classNames('w-50 w-xs-100 me-2', {
                                    'me-xs-0': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" && allWatchedValues?.event_details[nestIndex]?.event_type !== "GROUP_7"
                                })}>
                                    <Form.Group>
                                        <Form.Label className='fs-14 fw-500'>Age group</Form.Label>
                                        <Form.Select
                                            {...register(`event_details[${nestIndex}].age_groups[${index}].age_group`, {
                                                required: "Age Group is required"
                                            })}
                                            disabled={!allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled}
                                        >
                                            <option value="BELOW_AGE">Below age</option>
                                            <option value="ABOVE_AGE">Above age</option>
                                            {!(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_CAPTAIN") && <option value="TOTAL_BELOW_AGE">Total below age</option>}
                                            {!(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_CAPTAIN") && <option value="TOTAL_ABOVE_AGE">Total above age</option>}
                                            <option value="OPEN">Open</option>
                                            <option value="AMATEUR">Amateur</option>
                                            {(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_DOUBLE") && <option value="SEMI_PRO">Semi-Pro</option>}
                                            <option value="BAKAT_BARU">Bakat Baru</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                {(allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "OPEN") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "AMATEUR") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "BAKAT_BARU") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "BAKAT_BARU") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "SEMI_PRO") &&
                                    <div className='w-50 w-xs-100 mt-3 mt-sm-0'>
                                        <Form.Group>
                                            <Form.Label className='fs-14 fw-500'>Value of Age</Form.Label>
                                            <Controller
                                                control={control}
                                                name={`event_details[${nestIndex}].age_groups[${index}].age_value`}
                                                render={({ field }) => {
                                                    return (
                                                        <Form.Control
                                                            type="number"
                                                            {...field}
                                                            onChange={(e) => field.onChange(parseInt(e.target.value))}
                                                        />
                                                    )
                                                }}
                                                rules={{
                                                    required: "Age Value is required"
                                                }}
                                            />
                                            {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.age_value && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.age_value?.message}</ErrorText>}
                                        </Form.Group>
                                    </div>
                                }
                            </div>
                            <div className="d-flex flex-column flex-sm-row align-items-start w-100 pe-2">
                                <div className={classNames({
                                    'w-50 w-xs-100 me-2': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7",
                                    'd-none': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" && allWatchedValues?.event_details[nestIndex]?.event_type !== "GROUP_7"
                                })}>
                                    <Form.Group>
                                        <Form.Label className='fs-14 fw-500'>Number of Male</Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...register(`event_details[${nestIndex}].age_groups[${index}].number_of_males`, {
                                                required: (allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7") ? "Number of Male is required" : false,
                                                max: {
                                                    value: (allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3") ? 2 : (allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7") ? 7 : 3,
                                                    message: (allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3") ? "You can't add more than 2 male" : (allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7") ? "You can't add more than 7 male" : "You can't add more than 3 male"
                                                },
                                                min: {
                                                    value: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? 1 : 0,
                                                    message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You need to add at least 1 male" : ""
                                                }
                                            })}
                                            disabled={!allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled}
                                        />
                                        {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.number_of_males && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.number_of_males?.message}</ErrorText>}
                                    </Form.Group>
                                </div>
                                <div className={classNames({
                                    'w-50 w-xs-100 mt-3 mt-sm-0': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7",
                                    'd-none': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" && allWatchedValues?.event_details[nestIndex]?.event_type !== "GROUP_7"
                                })}>
                                    <Form.Group>
                                        <Form.Label className='fs-14 fw-500'>Number of Female</Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...register(`event_details[${nestIndex}].age_groups[${index}].number_of_females`, {
                                                required: (allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" || allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7") ? "Number of Female is required" : false,
                                                max: {
                                                    value: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? 2 : allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7" ? 7 : 3,
                                                    message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You can't add more than 2 female" : allWatchedValues?.event_details[nestIndex]?.event_type === "GROUP_7" ? "You can't add more than 7 female" : "You can't add more than 3 female"
                                                },
                                                min: {
                                                    value: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? 1 : 0,
                                                    message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You need to add at least 1 female" : ""
                                                }
                                            })}
                                            disabled={!allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled}
                                        />
                                        {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.number_of_females && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.number_of_females?.message}</ErrorText>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="w-100 d-flex flex-column flex-sm-row align-items-start mt-3 pe-2">
                                <div className={classNames('w-50 w-xs-100 me-2 me-xs-0', {
                                    "d-none": allWatchedValues?.category_type === "TEAM"
                                })}>
                                    <Form.Group>
                                        <Form.Label className='fs-14 fw-500'>Price</Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...register(`event_details[${nestIndex}].age_groups[${index}].price`, {
                                                required: allWatchedValues?.category_type === "INDIVIDUAL" ? "Price is required" : false
                                            })}
                                            disabled={!allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled}
                                        />
                                        {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.price && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.price?.message}</ErrorText>}
                                    </Form.Group>
                                </div>
                                <div className='w-50 w-xs-100 mt-3 mt-sm-0'>
                                    <Form.Group>
                                        <Form.Label className='fs-14 fw-500'>Number of participant</Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...register(`event_details[${nestIndex}].age_groups[${index}].allowed_registrations`, {
                                                required: "Number of participant is required",
                                                min: {
                                                    value: tournament?.event_details?.some(tournamentItem => tournamentItem?.event_type === allWatchedValues?.event_details[nestIndex]?.event_type && tournamentItem?.age_group === allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group) ? tournament?.event_details?.find(tournamentItem => tournamentItem?.event_type === allWatchedValues?.event_details[nestIndex]?.event_type && tournamentItem?.age_group === allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group)?.registered_players : 0,
                                                    message: `Minimum Number of participant is ${tournament?.event_details?.find(tournamentItem => tournamentItem?.event_type === allWatchedValues?.event_details[nestIndex]?.event_type && tournamentItem?.age_group === allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group)?.registered_players || 0}`
                                                }
                                            })}
                                            disabled={!allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled}
                                        />
                                        {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.allowed_registrations && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.allowed_registrations?.message}</ErrorText>}
                                        {parseInt(allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.allowed_registrations) === tournament?.event_details?.find(tournamentItem => tournamentItem?.event_type === allWatchedValues?.event_details[nestIndex]?.event_type && tournamentItem?.age_group === allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group)?.registered_players && <ErrorText>Registration is full</ErrorText>}
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <Form.Group className='d-flex flex-column align-items-end'>
                            <Form.Check
                                type="switch"
                                className='age-group-switch'
                                {...register(`event_details[${nestIndex}].age_groups[${index}].is_disabled`)}
                            />
                            <Form.Label className='fs-14 fw-500'>{allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled ? "Enable" : "Disable"}</Form.Label>
                        </Form.Group>
                    </div>

                    {fields.length > 1 && <IoMdClose role='button' className='position-absolute top-0 end-0' color='red' size={20} onClick={() => remove(index)} />}
                </div>
                <Form.Check
                    type='checkbox'
                    className='mt-2'
                    label={<span role='button' className='fw-500 text-secondary'>
                        Allow State/District/National Player to join?
                    </span>}
                    id={`allow-join[${nestIndex}][${index}`}
                    required={(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_DOUBLE") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group === "SEMI_PRO")}
                    {...register(`event_details[${nestIndex}].age_groups[${index}].allow_proffesional_players`)}
                    disabled={!allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.is_disabled}
                />
            </div>
        })}

        <div role='button' className='d-inline-flex align-items-center mt-4' onClick={() =>
            append({
                age_group: "BELOW_AGE",
                age_value: "",
                price: "",
                number_of_males: "",
                number_of_females: "",
                allowed_registrations: "",
                is_disabled: true,
                allow_proffesional_players: false
            })
        }>
            <BsFillPlusCircleFill className='text-primary me-2' size={25} />
            <span className='text-primary fw-500'>Add more age group</span>
        </div>
    </div>
};

export default AgeGroup;