const PickleBall = (props) => {
    return <svg width="25" height="24" className={props.className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12" r="12" fill="#E5D913" fillOpacity="0.5" />
        <path d="M18.1027 7.51812C18.1027 8.31336 17.4579 8.95811 16.6627 8.95811C15.8674 8.95811 15.2227 8.31336 15.2227 7.51812C15.2227 6.72288 15.8674 6.07812 16.6627 6.07812C17.4579 6.07812 18.1027 6.72288 18.1027 7.51812Z" fill="#D9DDE4" />
        <path d="M14.2628 11.9986C14.2628 12.7938 13.6181 13.4386 12.8228 13.4386C12.0276 13.4386 11.3828 12.7938 11.3828 11.9986C11.3828 11.2033 12.0276 10.5586 12.8228 10.5586C13.6181 10.5586 14.2628 11.2033 14.2628 11.9986Z" fill="#D9DDE4" />
        <path d="M6.85336 21.1986C7.84504 21.7973 8.84166 21.9637 9.07922 21.5702C9.31677 21.1768 8.70535 20.3723 7.71367 19.7736C6.72199 19.1749 5.72536 19.0085 5.48781 19.4019C5.25026 19.7954 5.86168 20.5998 6.85336 21.1986Z" fill="#D9DDE4" />
        <path d="M20.8663 7.69378C21.4055 8.71906 22.1724 9.37688 22.5792 9.16296C22.986 8.94904 22.8787 7.94433 22.3396 6.91905C21.8004 5.89377 21.0335 5.23595 20.6267 5.44987C20.2199 5.66379 20.3272 6.6685 20.8663 7.69378Z" fill="#D9DDE4" />
        <path d="M12.8401 2.96776C13.9984 2.98103 14.9418 2.61918 14.9471 2.15959C14.9524 1.7 14.0175 1.31661 12.8592 1.30333C11.7009 1.29006 10.7574 1.65193 10.7522 2.11151C10.7469 2.5711 11.6818 2.95448 12.8401 2.96776Z" fill="#D9DDE4" />
        <path d="M16.4592 22.2877C17.556 21.9149 18.3253 21.2599 18.1774 20.8247C18.0295 20.3895 17.0203 20.3389 15.9235 20.7117C14.8267 21.0845 14.0574 21.7395 14.2053 22.1747C14.3532 22.6099 15.3624 22.6605 16.4592 22.2877Z" fill="#D9DDE4" />
        <path d="M22.6667 16.0068C23.0988 14.932 23.1034 13.9216 22.677 13.7501C22.2505 13.5787 21.5544 14.3111 21.1223 15.3858C20.6902 16.4606 20.6856 17.471 21.112 17.6425C21.5385 17.8139 22.2346 17.0815 22.6667 16.0068Z" fill="#D9DDE4" />
        <path d="M3.72247 13.5967C3.56069 12.4497 3.06053 11.5717 2.60542 11.6359C2.1503 11.7001 1.91247 12.6822 2.07425 13.8292C2.23602 14.9763 2.73619 15.8542 3.1913 15.79C3.64642 15.7258 3.88424 14.7438 3.72247 13.5967Z" fill="#D9DDE4" />
        <path d="M5.70449 6.19052C6.48241 5.33219 6.83701 4.38603 6.49645 4.07738C6.15589 3.76872 5.24905 4.21438 4.47113 5.07271C3.69321 5.93104 3.33862 6.87721 3.67918 7.18586C4.01974 7.49452 4.92657 7.04885 5.70449 6.19052Z" fill="#D9DDE4" />
        <path d="M20.0206 11.9986C20.0206 12.7938 19.3759 13.4386 18.5806 13.4386C17.7854 13.4386 17.1406 12.7938 17.1406 11.9986C17.1406 11.2033 17.7854 10.5586 18.5806 10.5586C19.3759 10.5586 20.0206 11.2033 20.0206 11.9986Z" fill="#D9DDE4" />
        <path d="M7.28718 16.6492C7.96797 17.0602 8.85317 16.8415 9.26419 16.1607C9.67521 15.4799 9.4565 14.5947 8.77571 14.1837C8.09493 13.7727 7.20972 13.9914 6.7987 14.6722C6.38768 15.353 6.6064 16.2382 7.28718 16.6492Z" fill="#D9DDE4" />
        <path d="M18.423 16.4791C18.423 17.2743 17.7782 17.919 16.983 17.919C16.1877 17.919 15.543 17.2743 15.543 16.4791C15.543 15.6838 16.1877 15.0391 16.983 15.0391C17.7782 15.0391 18.423 15.6838 18.423 16.4791Z" fill="#D9DDE4" />
        <path d="M11.7637 18.8894C12.4445 19.3005 13.3297 19.0817 13.7408 18.401C14.1518 17.7202 13.9331 16.835 13.2523 16.4239C12.5715 16.0129 11.6863 16.2317 11.2753 16.9124C10.8642 17.5932 11.083 18.4784 11.7637 18.8894Z" fill="#D9DDE4" />
        <path d="M12.6612 6.55913C12.6612 7.35437 12.0165 7.99913 11.2213 7.99913C10.426 7.99913 9.78125 7.35437 9.78125 6.55913C9.78125 5.7639 10.426 5.11914 11.2213 5.11914C12.0165 5.11914 12.6612 5.7639 12.6612 6.55913Z" fill="#D9DDE4" />
        <path d="M6.64656 11.5301C7.32734 11.9411 8.21255 11.7224 8.62356 11.0416C9.03458 10.3608 8.81587 9.47559 8.13509 9.06457C7.4543 8.65355 6.56909 8.87228 6.15808 9.55307C5.74706 10.2339 5.96578 11.1191 6.64656 11.5301Z" fill="#D9DDE4" />
    </svg>
}

export default PickleBall;