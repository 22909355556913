import axios from "axios"

/**
    * @function allTShirtService
    * @param { paginate, page, perPage, tournamentId, size }
    * @description This function is used to get all t-shirt for organizer
*/
export const allTShirtService = async ({ paginate, page, perPage, tournamentId, size }) => {
    try {
        const result = await axios.get(`/organizer/tshirt/get/allTshirts?paginate=${paginate}&page=${page}&perPage=${perPage}&tournamentId=${tournamentId}&size=${size}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allTShirtSizeService
    * @param
    * @description This function is used to get all t-shirt size for organizer
*/
export const allTShirtSizeService = async () => {
    try {
        const result = await axios.get(`/organizer/tshirt/get/sizes/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addTShirtService
    * @param data
    * @description This function is used to add t-shirt
*/
export const addTShirtService = async (data) => {
    try {
        const result = await axios.post(`/organizer/tshirt/create`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateTShirtService
    * @param { id, data }
    * @description This function is used to update t-shirt
*/
export const updateTShirtService = async ({ id, data }) => {
    try {
        const result = await axios.post(`/organizer/tshirt/update/${id}`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateTShirtStockStatusService
    * @param { id }
    * @description This function is used to update t-shirt stock status
*/
export const updateTShirtStockStatusService = async ({ id }) => {
    try {
        const result = await axios.put(`/organizer/tshirt/update/stock-status/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateOrganizerTShirtStatusService
    * @param
    * @description This function is used to update organizer t-shirt status
*/
export const updateOrganizerTShirtStatusService = async () => {
    try {
        const result = await axios.put(`/organizer/tshirt/update/organization/tshirt-status`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getCustomerTShirtService
    * @param { id }
    * @description This function is used to get t-shirt list of an specific tournament for customer
*/
export const getCustomerTShirtService = async ({ id }) => {
    try {
        const result = await axios.get(`/customer/tshirt/get/tournament/tshirt/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerTShirtService
    * @param { id }
    * @description This function is used to get t-shirt list of an specific tournament for organizer
*/
export const getOrganizerTShirtService = async ({ id }) => {
    try {
        const result = await axios.get(`/organizer/tshirt/get/tournament/tshirt/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}