import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, Form, InputGroup, Spinner } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from "../../components/Button";
import ErrorText from '../../components/TextField/ErrorText'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { Avatar } from '../../assets/images/svg'

// Utility Service
import { setUserSession } from '../../utils/AuthService';

// API Service
import { allCountryService } from '../../service/country.service';
import { updateProfileImageService, updateProfileService, updatOrganizerProfileImageService, updateOrganizerProfileService } from '../../service/profile.service';

// Redux-Functions
import { setUser, selectToken, selectUser } from "../../redux/userSlice"

const Profile = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([])
    const [profileImage, setProfileImage] = useState(null);

    const { register, control, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    // for setting default value while editing address
    useEffect(() => {
        if (user && countryList?.length > 0) {
            let defaultValues = {};
            if (user?.type === 'customer') {
                defaultValues.username = user?.username
                setProfileImage(user?.profile_pic)
            }
            if (user?.type === 'organizer') {
                defaultValues.name = user?.name
                defaultValues.address = user?.address
                defaultValues.latitude = user?.latitude
                defaultValues.longitude = user?.longitude
                setProfileImage(user?.profile_image)
            }
            defaultValues.country_id = user?.country_id || countryList[0]?.id
            defaultValues.phone = user?.phone
            defaultValues.email = user?.email
            defaultValues.gender = user?.gender
            reset({ ...defaultValues })
        } else {
            reset()
            setProfileImage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, countryList])

    /**
        * @function removePhoto
        * @params
        * @description used for remove profile photo
    */
    const removePhoto = async () => {
        setProfileImage()
    }

    /**
        * @function handleSelect
        * @params address
        * @description used for selecting the address
    */
    const handleSelect = async (address) => {
        try {
            const result = await geocodeByAddress(address);
            const latAndLang = await getLatLng(result[0])
            setValue('address', address);
            setValue('latitude', latAndLang.lat);
            setValue('longitude', latAndLang.lng);
        } catch (error) {
            ErrorAlert(error)
        }
    };

    /**
        * @function onSubmit
        * @params formdata
        * @description used to update profile
    */
    const onSubmit = async (formdata) => {
        try {
            setLoading(true)
            let result;
            if (user?.type === 'customer') {
                const data = new FormData()
                data.append('username', formdata?.username)
                data.append('email', formdata?.email)
                data.append('phone', formdata?.phone)
                data.append('country_id', formdata?.country_id)
                data.append('gender', formdata?.gender)
                if (profileImage instanceof File) {
                    const imageData = new FormData()
                    imageData.append('profile_pic', profileImage)
                    await updateProfileImageService(imageData)
                } else if (!profileImage) {
                    await updateProfileImageService({})
                }
                result = await updateProfileService(data);
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    dispatch(setUser({
                        ...result.data?.data,
                        type: 'customer'
                    }))
                    setUserSession(token, {
                        ...result.data?.data,
                        type: 'customer'
                    })
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
            if (user?.type === 'organizer') {
                const data = new FormData()
                data.append('name', formdata?.name)
                data.append('email', formdata?.email)
                data.append('phone', formdata?.phone)
                data.append('country_id', formdata?.country_id)
                data.append('gender', formdata?.gender)
                if (formdata?.address && formdata?.latitude && formdata?.longitude) {
                    data.append('address', formdata?.address)
                    data.append('latitude', formdata?.latitude)
                    data.append('longitude', formdata?.longitude)
                }
                if (profileImage instanceof File) {
                    const imageData = new FormData()
                    imageData.append('profile_pic', profileImage)
                    await updatOrganizerProfileImageService(imageData)
                } else if (!profileImage) {
                    await updateProfileImageService({})
                }
                result = await updateOrganizerProfileService(data);
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    dispatch(setUser({
                        ...result.data?.data,
                        type: 'organizer'
                    }))
                    setUserSession(token, {
                        ...result.data?.data,
                        type: 'organizer'
                    })
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Profile | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <p className='fs-25 fw-600 mt-4 px-3'>My Profile</p>
        <Row>
            <Col lg={6}>
                <Form className='mt-4 px-3' onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='fs-18 fw-600'>Personal information</span>
                        <div>
                            <PrimaryButton disabled={loading} loading={loading} type='submit'>Save Changes</PrimaryButton>
                        </div>
                    </div>

                    <div className='d-flex flex-column'>
                        {profileImage instanceof File
                            ?
                            <img className='profile-photo rounded-circle' src={URL.createObjectURL(profileImage)} alt={user?.username || user?.name} />
                            :
                            profileImage ?
                                <img className='profile-photo rounded-circle' src={profileImage} alt={user?.username || user?.name} />
                                :
                                <Avatar />
                        }

                        <div className='d-flex align-items-center mt-3'>
                            <input className='d-none' type='file' id="profilePhotoUpload" accept="image/*" onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    setProfileImage(e.target.files[0])
                                }
                            }} onClick={(e) => e.target.value = null} />
                            <label htmlFor="profilePhotoUpload">
                                <span role='button' className='fs-14 fw-500 text-light bg-primary py-2 px-3 rounded'>Change Photo</span>
                            </label>
                            <div className='ms-3'>
                                <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={removePhoto}>Remove Photo</PrimaryOutlineButton>
                            </div>
                        </div>
                    </div>

                    {user?.type === 'customer' &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Username*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Username"
                                {...register("username", {
                                    required: "Username is required"
                                })}
                            />
                            {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
                        </Form.Group>
                    }

                    {user?.type === 'organizer' &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Full Name*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Username"
                                {...register("name", {
                                    required: "Full Name is required"
                                })}
                            />
                            {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                        </Form.Group>
                    }

                    {countryList.length > 0 &&
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                            <InputGroup className='rounded'>
                                <InputGroup.Text className='p-0 border-0'>
                                    <Form.Select
                                        className='border-end-0'
                                        disabled={user?.type === 'organizer'}
                                        {...register("country_id")}
                                    >
                                        {countryList.map((item, index) => {
                                            return <option value={item?.id} key={index}>{item?.code}</option>
                                        })}
                                    </Form.Select>
                                </InputGroup.Text>
                                <Form.Control
                                    className='border-start-0 ps-0'
                                    type="tel"
                                    disabled={user?.type === 'organizer'}
                                    placeholder="Enter Phone number"
                                    {...register("phone", {
                                        required: "Phone is required"
                                    })}
                                />
                            </InputGroup>
                            {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                        </Form.Group>
                    }

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Email address*</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            disabled={user?.type === 'organizer'}
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Gender</Form.Label>
                        <Form.Select
                            {...register("gender")}
                        >
                            <option value='MALE'>Male</option>
                            <option value='FEMALE'>Female</option>
                        </Form.Select>
                    </Form.Group>

                    {user?.type === 'organizer' &&
                        <Controller
                            control={control}
                            name={`address`}
                            render={({ field }) => (
                                <PlacesAutocomplete
                                    value={field.value}
                                    onChange={field.onChange}
                                    onSelect={handleSelect}
                                    debounce={300}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <Form.Group className="my-4">
                                            <Form.Label className='fs-14 fw-500'>Location</Form.Label>
                                            <Form.Control
                                                {...getInputProps({
                                                    placeholder: 'Search Address',
                                                    className: 'bg-transparent rounded-3'
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div className='w-100 text-center mt-3'>
                                                    <Spinner animation="border" />
                                                </div>
                                                }
                                                {suggestions.map(suggestion => {
                                                    return <div className='mt-4' {...getSuggestionItemProps(suggestion)}>
                                                        <span role='button' className={`${suggestion.active ? 'fw-bold' : 'fw-400'}`}>{suggestion.description}</span>
                                                    </div>
                                                })}
                                            </div>
                                        </Form.Group>
                                    )}
                                </PlacesAutocomplete>
                            )}
                        />
                    }
                </Form>
            </Col>
        </Row>
    </HelmetProvider>
};

export default Profile;