import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button'
import AuthFooter from '../../components/Common/AuthFooter'
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { logo, authentication } from '../../assets/images'

// Utility Service
import { setUserSession, updateUserSession } from '../../utils/AuthService';

// API Service
import { organizerLoginService } from '../../service/auth.service';
import { allCountryService } from '../../service/country.service';
import { getOrganizerProfileService } from '../../service/profile.service';

// Redux-Functions
import { setToken, setUser } from "../../redux/userSlice"

const OrganizerSignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });

    /**
        * @function fetchCountryList
        * @params
        * @description fetch the list of country
    */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    /**
        * @function fetchOrganizerInfo
        * @params
        * @description fetch the info of organizer
    */
    const fetchOrganizerInfo = async () => {
        try {
            const result = await getOrganizerProfileService();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'organizer'
                }))
                updateUserSession({
                    ...result.data?.data,
                    type: 'organizer'
                })
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handleShowPassword
        * @params
        * @description toggle the password input field visibility
    */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
        * @function onSubmit
        * @params formdata
        * @description used to signin the organization
    */
    const onSubmit = async (formdata) => {
        try {
            setLoading(true)
            const result = await organizerLoginService({
                phone: formdata.phone,
                password: formdata.password
            });
            if (result?.data?.status) {
                if (result.data?.data?.is_verified) {
                    dispatch(setToken(result?.data?.data?.token))
                    dispatch(setUser({
                        ...result.data?.data?.organizer,
                        type: 'organizer'
                    }))
                    setUserSession(result.data?.data?.token, {
                        ...result.data?.data?.organizer,
                        type: 'organizer'
                    })
                    await fetchOrganizerInfo()
                    navigate('/');
                } else {
                    ErrorAlert('This Organizer is not verified')
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Organizer Sign In | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='pe-lg-0'>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" />
                    </Link>
                    <Row className="justify-content-center align-items-center flex-grow-1 w-100 mt-5">
                        <Col xs={12} sm={8}>
                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <span className='fs-30 fw-bold'>Organizer Login</span>
                                {countryList.length > 0 &&
                                    <Form.Group className="my-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <InputGroup.Text className='p-0 border-0'>
                                                <Form.Select
                                                    className='border-end-0'
                                                    {...register("country_id")}
                                                >
                                                    {countryList.map((item, index) => {
                                                        return <option value={item?.code} key={index}>{item?.code}</option>
                                                    })}
                                                </Form.Select>
                                            </InputGroup.Text>
                                            <Form.Control
                                                className='border-start-0 ps-0'
                                                type="tel"
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Phone is required"
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>
                                }

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required"
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>

                                <Form.Group className="mb-3 mt-4">
                                    <PrimaryButton disabled={loading} loading={loading} type="submit">Sign In</PrimaryButton>
                                </Form.Group>
                            </Form>
                            <hr className='mx-5 my-3' />
                            <div className='text-center mt-4 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Back to
                                    <Link to='/login' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Player Login</span>
                                    </Link>
                                </span>
                            </div>
                        </Col>
                        <AuthFooter />
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default OrganizerSignIn;