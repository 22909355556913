import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";

// Custom Component
import Breadcrumb from "../../components/Common/Breadcrumb";
import { PrimaryButton } from "../../components/Button";
import ErrorAlert from '../../components/Alert/ErrorAlert';
import PaymentConfirmModal from "../../components/Modal/PaymentConfirmModal";

// Images
import { Badminton, PickleBall, User, Users } from '../../assets/images/svg';

// Utils
import { detectMenEvent, detectWomenEvent, convertEventType, convertAgeGroup, convertGender } from '../../utils/helper';

// API Service
import { createPaymentService } from '../../service/payment.service';
import { removePlayerService } from '../../service/tournament.service';

const TournamentSummary = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [tournament, setTournament] = useState(state?.tournament)
    const [paymentConfirmModal, setPaymentConfirmModal] = useState(false)

    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function (event) {
        navigate('/enter-tournament', {
            state: {
                tournamentId: tournament?.id,
                player_details: tournament?.player_details,
                edit: true
            }
        });
    })

    useEffect(() => {
        if (tournament && tournament?.player_details?.length === 0) {
            navigate('/enter-tournament', {
                state: {
                    tournamentId: tournament?.id
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournament])

    /**
        * @function createPayment
        * @params
        * @description used to create payment
    */
    const createPayment = async () => {
        try {
            const data = new FormData()
            if (tournament?.category_type === "INDIVIDUAL" && tournament?.player_details?.length > 0) {
                data.append('amount', parseFloat(tournament?.player_details[0].event_price).toFixed(2))
            } else if (tournament?.category_type === "TEAM") {
                data.append('amount', parseFloat(tournament?.team_event_price).toFixed(2))
            }
            data.append('tournament_id', tournament?.id)
            data.append('team_id', tournament?.player_details?.find(item => item?.team_id)?.team_id)
            const result = await createPaymentService(data);
            if (result?.data?.status) {
                window.location.href = result?.data?.data
                handlePaymentConfirmModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function removePlayer
        * @params playerId, tournamentId
        * @description used to remove player
    */
    const removePlayer = async (playerId, tournamentId) => {
        try {
            const result = await removePlayerService(tournamentId, playerId);
            if (result?.data?.status) {
                setTournament({
                    ...tournament,
                    player_details: tournament?.player_details.filter(item => item.id !== playerId)
                })
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handlePaymentConfirmModal
        * @params
        * @description set the visibility of payment confirm modal
    */
    const handlePaymentConfirmModal = () => {
        setPaymentConfirmModal(!paymentConfirmModal)
    }


    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Tournament Summary | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Breadcrumb text='Tournament Summary' />
        <p className='fs-25 fw-600 mt-4'>Tournament Summary</p>
        <Row>
            <Col lg={7}>
                <div className='d-flex flex-column align-items-start'>
                    <span className='fs-22 fw-600 text-primary text-capitalize'>{tournament?.name}</span>
                </div>
                <div className='d-flex flex-column align-items-start border rounded px-3 py-2 my-4'>
                    <span className='fs-18 fw-600 text-secondary'>Type of Sports</span>
                    {tournament?.sports_type === "BADMINTON" &&
                        <div className='d-flex flex-column align-items-center text-light bg-primary rounded py-2 px-3 mt-3'>
                            <Badminton className='img-fluid mt-2 mb-2' />
                            <span>Badminton</span>
                        </div>
                    }
                    {tournament?.sports_type === "PICKLEBALL" &&
                        <div className='d-flex flex-column align-items-center text-light bg-primary rounded py-2 px-3 mt-3'>
                            <PickleBall className='img-fluid mt-2 mb-2' />
                            <span>Pickleball</span>
                        </div>
                    }
                </div>

                <div className='d-flex flex-column align-items-start border rounded px-3 py-2 my-4'>
                    <span className='fs-18 fw-600'>Categories</span>
                    <div className='d-flex justify-content-between align-items-center mt-2 w-100'>
                        {tournament?.category_type === "INDIVIDUAL" && <div className='d-flex align-items-center'>
                            <User className='me-2' color={'#FA5000'} />
                            Individuals Event
                        </div>}
                        {tournament?.category_type === "TEAM" && <div className='d-flex align-items-center'>
                            <Users className='me-2' color={'#FA5000'} />
                            Team Event
                        </div>}
                        {tournament?.category_type === "TEAM" && <span className='fw-600'>RM{parseFloat(tournament?.team_event_price).toFixed(2)}</span>}
                    </div>
                </div>

                <div className='d-flex flex-column align-items-start border rounded px-3 py-2 my-4'>
                    <span className='fs-18 fw-600'>Type of Events</span>
                    {tournament?.player_details?.length > 0 &&
                        <div className='d-flex flex-wrap align-items-center mt-2'>
                            {tournament?.player_details[0]?.team_event_type ? tournament?.player_details[0]?.team_event_type?.map((item, index) => {
                                return <div className='bg-primary me-2 px-3 py-1 rounded mt-2' key={index}>
                                    <div className='d-flex align-items-center text-primary'>
                                        {detectMenEvent({
                                            event: item,
                                            color: 'white',
                                            className: 'me-2'
                                        })}
                                        {detectWomenEvent({
                                            event: item,
                                            color: 'white',
                                            className: 'me-2'
                                        })}
                                        <span className='text-light'>
                                            {item && convertEventType(item)}
                                            {tournament?.player_details[0]?.team_event_age_group[index] && tournament?.player_details[0]?.team_event_age_value[index] && ` (${convertAgeGroup(tournament?.player_details[0]?.team_event_age_group[index], tournament?.player_details[0]?.team_event_age_value[index])})`}
                                        </span>
                                    </div>
                                </div>
                            })
                                :
                                <div className='bg-primary me-2 px-3 py-1 rounded mt-2'>
                                    <div className='d-flex align-items-center text-primary'>
                                        {tournament?.player_details?.length > 0 && detectMenEvent({
                                            event: tournament?.player_details[0]?.event_type,
                                            color: 'white',
                                            className: 'me-2'
                                        })}
                                        {tournament?.player_details?.length > 0 && detectWomenEvent({
                                            event: tournament?.player_details[0]?.event_type,
                                            color: 'white',
                                            className: 'me-2'
                                        })}
                                        <span className='text-light'>
                                            {tournament?.player_details?.length > 0 && convertEventType(tournament?.player_details[0]?.event_type)}
                                            {tournament?.player_details?.length > 0 && ` (${tournament?.player_details[0]?.age_group && tournament?.player_details[0]?.age_value && convertAgeGroup(tournament?.player_details[0]?.age_group, tournament?.player_details[0]?.age_value)})`}
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className='d-flex flex-column align-items-start border rounded px-3 py-2 my-4'>
                    <span className='fs-18 fw-600'>Players ({tournament?.player_details?.length || 0})</span>
                    {tournament?.player_details?.length > 0 && tournament?.player_details?.map((item, index) => {
                        return <div className='d-flex flex-column align-items-start mt-3 bg-danger bg-opacity-10 w-100 px-3 py-2 border rounded position-relative' key={index}>
                            <span className='fs-14 fw-600'>Player {index + 1}</span>
                            <span className='fw-600 text-primary'>{item?.player_name}</span>
                            <div className='d-flex flex-wrap justify-content-between align-items-center fs-14 fw-500 w-100'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <span className='me-2'>{item?.player_gender ? convertGender(item?.player_gender) : '-'}</span>
                                    <span className='me-2'>.</span>
                                    <span className='me-2'>{item?.player_email}</span>
                                    <span className='me-2'>.</span>
                                    <span>{item?.player_contact}</span>
                                </div>
                            </div>
                            {item?.tshirt_details &&
                                <span className='fs-14 fw-500 mt-1'>T-Shirt : {item?.tshirt_details?.tshirt?.tshirt_name} ({item?.tshirt_details?.size?.size})</span>
                            }
                            {tournament?.sports_type === "PICKLEBALL" && <span className='fs-14 fw-500 mt-1'>DUPR Rating : {item?.dupr_rating}</span>}
                            <IoMdClose role='button' className='position-absolute top-0 end-0 mt-2 me-3' color='red' size={20} onClick={() => removePlayer(item?.id, tournament?.id)} />
                        </div>
                    })}
                </div>
            </Col>
            <Col lg={5} className='mb-3 mb-lg-0'>
                <div className='d-flex flex-column align-items-start bg-light border rounded p-3'>
                    <span className='fs-22 fw-600 text-secondary'>Details</span>
                    {tournament?.category_type === "INDIVIDUAL" &&
                        <div className='d-flex justify-content-between align-items-center w-100 mt-3 fw-600'>
                            <span>Original Price</span>
                            {tournament?.player_details?.length > 0 && <span>RM{parseFloat(tournament?.player_details[0].event_price).toFixed(2)}</span>}
                        </div>
                    }
                    {tournament?.category_type === "TEAM" &&
                        <div className='d-flex justify-content-between align-items-center w-100 mt-3 fw-600'>
                            <span>Team Event Price</span>
                            <span>RM{parseFloat(tournament?.team_event_price).toFixed(2)}</span>
                        </div>
                    }
                    <div className='d-flex justify-content-between align-items-center w-100 mt-3 fw-600'>
                        <span>Players</span>
                        <span>{tournament?.player_details?.length || 0}</span>
                    </div>
                    {tournament?.category_type === "INDIVIDUAL" && tournament?.player_details &&
                        <div className='d-flex justify-content-between align-items-center w-100 mt-3 fw-600'>
                            <span>Total Price</span>
                            {tournament?.player_details?.length > 0 && <span>RM{parseFloat(tournament?.player_details[0].event_price).toFixed(2)}</span>}
                        </div>
                    }
                    {tournament?.category_type === "TEAM" && tournament?.player_details &&
                        <div className='d-flex justify-content-between align-items-center w-100 mt-3 fw-600'>
                            <span>Total Price</span>
                            <span>RM{parseFloat(tournament?.team_event_price).toFixed(2)}</span>
                        </div>
                    }
                    <PrimaryButton className='fs-14 fw-500 my-3' onClick={handlePaymentConfirmModal}>
                        Complete Checkout
                        <BsArrowRight className='ms-2' />
                    </PrimaryButton>
                </div>
            </Col>
        </Row>

        {/* Payment Confirm Modal */}
        <PaymentConfirmModal
            show={paymentConfirmModal}
            handleClose={handlePaymentConfirmModal}
            createPayment={createPayment}
            tournament={tournament}
            size='lg'
        />
    </HelmetProvider>
};

export default TournamentSummary;