import { useState, useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTable } from "react-table";
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

// Custom Component
import { PrimaryOutlineButton } from "../../components/Button";
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import AddTShirtModal from '../../components/Modal/AddTShirtModal';
import EditTShirtModal from '../../components/Modal/EditTShirtModal';
import ViewTournamentModal from '../../components/Modal/ViewTournamentModal';

// Images
import { noTShirt } from '../../assets/images';

// Utility Service
import { updateUserSession } from '../../utils/AuthService';

// API Service
import { getOrganizerProfileService } from '../../service/profile.service';
import { allTournamentService } from '../../service/tournament.service';
// import { allTShirtService, allTShirtSizeService, updateTShirtStockStatusService, updateOrganizerTShirtStatusService } from '../../service/tshirt.service';
import { allTShirtService, updateTShirtStockStatusService, updateOrganizerTShirtStatusService } from '../../service/tshirt.service';

// Redux-Functions
import { setUser, selectUser } from "../../redux/userSlice";

const TShirtSetting = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [tournamentList, setTournamentList] = useState([])
    // const [sizeList, setSizeList] = useState([])
    const [tShirtList, setTShirtList] = useState([])
    const [selectedItem, setSelectedItem] = useState()
    const [selectedTournamentList, setSelectedTournamentList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [csvData, setCSVData] = useState([]);
    const [addTShirtModal, setAddTShirtModal] = useState(false)
    const [editTShirtModal, setEditTShirtModal] = useState(false)
    const [viewTournamentModal, setViewTournamentModal] = useState(false)

    const csvHeaders = [
        {
            label: 'T-Shirt',
            key: 'tshirt_name'
        },
        {
            label: 'Tournament',
            key: 'tournament_id'
        },
        {
            label: 'Size',
            key: 'size'
        },
        {
            label: 'Total Quantity',
            key: 'quantity'
        }
    ];

    const { control, watch } = useForm({
        mode: "all",
        defaultValues: {
            size: '',
            size_lg: '',
            tournament_id: '',
            tournament_id_lg: ''
        }
    })
    const watchSize = watch("size")
    const watchSizeLg = watch("size_lg")
    const watchTournamentId = watch("tournament_id")
    const watchTournamentIdLg = watch("tournament_id_lg")

    const reactSelectCustomStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        },
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#CCCCCC'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#CCCCCC'
        })
    }

    /**
        * @function fetchOrganizerInfo
        * @params
        * @description fetch the info of organizer
    */
    const fetchOrganizerInfo = async () => {
        try {
            const result = await getOrganizerProfileService();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'organizer'
                }))
                updateUserSession({
                    ...result.data?.data,
                    type: 'organizer'
                })
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchTournamentList
        * @params
        * @description fetch the list of tournament
    */
    const fetchTournamentList = async () => {
        try {
            const result = await allTournamentService({
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setTournamentList(result?.data?.data?.tournament_lists)
            } else {
                setTournamentList([])
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            setTournamentList([])
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchTShirtSizeList
        * @params
        * @description fetch the list of t-shirt size
    */
    // const fetchTShirtSizeList = async () => {
    //     try {
    //         const result = await allTShirtSizeService();
    //         if (result?.data?.status) {
    //             setSizeList(result?.data?.data)
    //         } else {
    //             setSizeList([])
    //             ErrorAlert(result?.response?.data?.message)
    //         }
    //     } catch (error) {
    //         setSizeList([])
    //         ErrorAlert(error)
    //     }
    // }

    /**
        * @function fetchTShirtList
        * @params page
        * @description fetch the list of t-shirt
    */
    const fetchTShirtList = async (page) => {
        try {
            const result = await allTShirtService({
                paginate: 1,
                page,
                perPage: 10,
                tournamentId: watchTournamentId?.value || watchTournamentIdLg?.value || '',
                size: watchSize?.value || watchSizeLg?.value || ''
            });
            if (result?.data?.status) {
                if (result?.data?.data?.tshirts_list?.length > 0) {
                    setTShirtList(_.flatMap(result?.data?.data?.tshirts_list, (item) =>
                        item.tshirt_details.map(tshirtDetail => ({
                            id: item?.id,
                            tshirt_name: item?.tshirt_name,
                            tournament: item?.tournaments,
                            tshirtDetailId: tshirtDetail?.id,
                            size: tshirtDetail?.size,
                            quantity: tshirtDetail?.quantity,
                            is_out_of_stock: tshirtDetail?.is_out_of_stock
                        }))
                    ))
                    setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
                } else {
                    setTShirtList([])
                    setTotalEntry(1)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchAllTShirtList
        * @params
        * @description fetch the list of all t-shirt
    */
    const fetchAllTShirtList = async () => {
        try {
            const result = await allTShirtService({
                paginate: 0,
                page: '',
                tournamentId: '',
                size: ''
            });
            if (result?.data?.status) {
                if (result?.data?.data?.tshirts_list?.length > 0) {
                    const tempData = _.flatMap(result?.data?.data?.tshirts_list, (item) =>
                        item.tshirt_details.map(tshirtDetail => ({
                            id: item?.id,
                            tshirt_name: item?.tshirt_name,
                            tournament: item?.tournaments,
                            tshirtDetailId: tshirtDetail?.id,
                            size: tshirtDetail?.size,
                            quantity: tshirtDetail?.quantity,
                            is_out_of_stock: tshirtDetail?.is_out_of_stock
                        }))
                    )

                    let rows = [];
                    let prevTShirtId = '';
                    let tshirtRowSpan = 0;

                    tempData.forEach((item, index) => {
                        if (item?.id !== prevTShirtId) {
                            prevTShirtId = item?.id;
                            tshirtRowSpan = 1;
                        } else {
                            tshirtRowSpan++;
                        }

                        rows.push({
                            id: item?.id,
                            tshirt_name: tshirtRowSpan > 1 && index !== 0 && tempData[index - 1]?.id === item?.id ? '' : item?.tshirt_name,
                            tournament_id: tshirtRowSpan > 1 && index !== 0 && tempData[index - 1]?.id === item?.id ? '' : item?.tournament?.length > 0 ? _.join(item?.tournament?.map(tournament => tournament?.name), ', ') : "",
                            size: item.size,
                            quantity: item.quantity
                        });
                        setCSVData(rows)
                    });
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function statusUpdate
        * @params id
        * @description used to change stock status
    */
    const stockStatusUpdate = async (id) => {
        try {
            const result = await updateTShirtStockStatusService({
                id
            });
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                await fetchTShirtList(1);
                await fetchAllTShirtList();
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function organizerTShirtStatusUpdate
        * @params
        * @description used to change organizer t-shirt status
    */
    const organizerTShirtStatusUpdate = async () => {
        try {
            const result = await updateOrganizerTShirtStatusService();
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                await fetchOrganizerInfo();
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchTournamentList()
            // fetchTShirtSizeList()
            fetchOrganizerInfo()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchAllTShirtList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchTShirtList(currentPage + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, watchSize, watchSizeLg, watchTournamentId, watchTournamentIdLg])

    /**
        * @function handleAddTShirtModal
        * @params
        * @description set the visibility of add t-shirt modal
    */
    const handleAddTShirtModal = () => {
        setAddTShirtModal(!addTShirtModal)
    }

    /**
        * @function handleEditTShirtModal
        * @params item
        * @description set the visibility of edit t-shirt modal
    */
    const handleEditTShirtModal = (item) => {
        setSelectedItem(_.head(_.chain(tShirtList?.filter(tshirt => tshirt?.id === item?.id)).groupBy('id')?.map((items) => {
            return {
                id: item?.id,
                tshirt_name: item?.tshirt_name,
                tournament: item?.tournament,
                tshirt_details: items.map(tshirtDetails => ({
                    id: tshirtDetails?.tshirtDetailId,
                    size: tshirtDetails?.size,
                    quantity: tshirtDetails?.quantity
                }))
            };
        })?.value()))
        setEditTShirtModal(!editTShirtModal)
    }

    /**
        * @function handleViewTournamentModal
        * @params item
        * @description set the visibility of view tournament modal
    */
    const handleViewTournamentModal = (item) => {
        setSelectedTournamentList(item)
        setViewTournamentModal(!viewTournamentModal)
    }

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    const useInstance = (instance) => {
        const { allColumns } = instance;
        let rowSpanHeaders = [];

        allColumns.forEach((column) => {
            if (column.enableRowSpan !== undefined) {
                rowSpanHeaders.push({
                    id: column.id,
                    topCellValue: null,
                    topCellIndex: -1,
                    rowSpan: 1
                });
            }
        });

        Object.assign(instance, { rowSpanHeaders });
    };

    const columns = useMemo(() => [
        {
            Header: "T-Shirt",
            accessor: "tshirt_name",
            enableRowSpan: true,
            minWidth: 150
        },
        {
            Header: "Tournament",
            Cell: ({ row }) => <span>
                {row?.original?.tournament?.length > 0 ?
                    row?.original?.tournament?.map(tournament => tournament?.name).length > 2 ?
                        <>
                            {_.join(_.slice(row?.original?.tournament?.map(tournament => tournament?.name), 0, 2), ', ')}
                            <span role="button" className='text-primary ms-1' onClick={() => handleViewTournamentModal(row?.original?.tournament)}>+{row?.original?.tournament?.map(tournament => tournament?.name).length - 2} more</span>
                        </>
                        :
                        _.join(row?.original?.tournament?.map(tournament => tournament?.name), ', ') : ""
                }
            </span>,
            enableRowSpan: true
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Total Quantity",
            accessor: "quantity"
        },
        {
            Header: "T-Shirt Option Status",
            Cell: ({ row }) => <Form.Check
                type="switch"
                checked={!row?.original?.is_out_of_stock}
                onChange={() => stockStatusUpdate(row?.original?.tshirtDetailId)}
            />
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <FiEdit3
                    role='button'
                    className='text-secondary'
                    size={20}
                    onClick={() => handleEditTShirtModal(row?.original)}
                />
            ),
            enableRowSpan: true
        }
        // eslint-disable-next-line
    ], [tShirtList, editTShirtModal]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        rowSpanHeaders
    } = useTable({ columns, data: tShirtList }, hooks => {
        hooks.useInstance.push(useInstance);
    });

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>T-Shirt Setting | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>T-Shirts</p>
            <div>
                <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={handleAddTShirtModal}>Add T-Shirts</PrimaryOutlineButton>
            </div>
        </div>
        {_.uniqBy(csvData, 'id')?.length > 0 ?
            <div className='d-flex flex-column align-items-start border rounded mb-3'>
                <div className='d-flex justify-content-between align-items-center p-3 ps-0 w-100'>
                    <div className='d-flex align-items-center'>
                        {!user?.parent_id &&
                            <Form.Check
                                className='me-3'
                                type="switch"
                                label={<span className='fw-600'>Enable</span>}
                                checked={!user?.is_tshirt_disabled}
                                onChange={organizerTShirtStatusUpdate}
                            />
                        }
                        <span className={classNames('fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded', {
                            'ms-3': user?.parent_id
                        })}>{_.uniqBy(csvData, 'id')?.length || tShirtList?.length || 0} t-shirts</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        {/* <Form.Group className='d-none d-lg-block me-lg-3'>
                            <Controller
                                name={`size_lg`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        styles={reactSelectCustomStyle}
                                        placeholder='Select size'
                                        options={sizeList?.length > 0 ? sizeList?.map((item) => {
                                            return {
                                                value: item,
                                                label: item
                                            }
                                        }) : []}
                                        isSearchable={true}
                                        isClearable={true}
                                        {...field}
                                    />
                                )}
                            />
                        </Form.Group> */}
                        <Form.Group className='d-none d-lg-block me-lg-3'>
                            <Controller
                                name={`tournament_id_lg`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        styles={reactSelectCustomStyle}
                                        placeholder='Select tournament'
                                        options={tournamentList?.length > 0 ? tournamentList?.map((item) => {
                                            return {
                                                value: item?.id,
                                                label: item?.name
                                            }
                                        }) : []}
                                        isSearchable={true}
                                        isClearable={true}
                                        {...field}
                                    />
                                )}
                            />
                        </Form.Group>
                        <CSVLink className="text-decoration-none" filename={`TShirts.csv`} data={csvData} headers={csvHeaders}>
                            <span role='button' className='fs-14 fw-600 text-primary p-2 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                        </CSVLink>
                    </div>
                </div>
                {tShirtList?.length > 0 ?
                    <>
                        <div className='d-flex flex-column d-lg-none align-items-center px-3 w-100'>
                            {/* <Form.Group className='mb-3 mb-lg-0 w-100'>
                                <Controller
                                    name={`size`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            styles={reactSelectCustomStyle}
                                            placeholder='Select size'
                                            options={sizeList?.length > 0 ? sizeList?.map((item) => {
                                                return {
                                                    value: item,
                                                    label: item
                                                }
                                            }) : []}
                                            isSearchable={true}
                                            isClearable={true}
                                            {...field}
                                        />
                                    )}
                                />
                            </Form.Group> */}
                            <Form.Group className='mb-3 mb-lg-0 w-100'>
                                <Controller
                                    name={`tournament_id`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            styles={reactSelectCustomStyle}
                                            placeholder='Select tournament'
                                            options={tournamentList?.length > 0 ? tournamentList?.map((item) => {
                                                return {
                                                    value: item?.id,
                                                    label: item?.name
                                                }
                                            }) : []}
                                            isSearchable={true}
                                            isClearable={true}
                                            {...field}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </div>
                        <div className='d-none d-lg-block overflow-x-auto w-100'>
                            <table className="table table-bordered mb-4" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    className="fs-12 fw-500"
                                                    {...column.getHeaderProps()}
                                                    style={{
                                                        minWidth: column.minWidth || "auto",
                                                        padding: "8px 10px",
                                                        backgroundColor: "#EAECF0"
                                                    }}
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map((row, i) => {
                                        prepareRow(row);

                                        row.cells.forEach((cell, j) => {
                                            let rowSpanHeader = rowSpanHeaders.find(x => x.id === cell.column.id);
                                            if (rowSpanHeader !== undefined) {
                                                if (cell.column.id === "tshirt_name") {
                                                    if (rowSpanHeader.topCellValue === null || rowSpanHeader.topCellValue !== cell.value) {
                                                        rowSpanHeader.topCellValue = cell.value;
                                                        rowSpanHeader.topCellIndex = i;
                                                        cell.rowSpan = tShirtList?.filter(tShirt => tShirt?.id === cell?.row?.original?.id)?.length;
                                                    } else {
                                                        const previousRow = rows[rowSpanHeader.topCellIndex];
                                                        if (previousRow) {
                                                            cell.isRowSpanned = true;
                                                        }
                                                    }
                                                } else if (cell.column.id === "Tournament" || cell.column.id === "Actions") {
                                                    const identifier = row.original.id
                                                    if (rowSpanHeader.topCellValue === null || rowSpanHeader.topCellValue !== identifier) {
                                                        rowSpanHeader.topCellValue = identifier;
                                                        rowSpanHeader.topCellIndex = i;
                                                        cell.rowSpan = tShirtList?.filter(tShirt => tShirt?.id === cell?.row?.original?.id)?.length;
                                                    } else {
                                                        const previousRow = rows[rowSpanHeader.topCellIndex];
                                                        if (previousRow) {
                                                            cell.isRowSpanned = true;
                                                        }
                                                    }
                                                }
                                            }
                                        });

                                        return null;
                                    })}
                                    {rows.map(row => (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.isRowSpanned) return null;
                                                return <td
                                                    {...cell.getCellProps()}
                                                    rowSpan={cell.rowSpan}
                                                    className={classNames('fs-14', {
                                                        'align-top': cell.rowSpan
                                                    })}
                                                    style={{
                                                        padding: "8px 10px"
                                                    }}>
                                                    {cell.render("Cell")}
                                                </td>
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-lg-none p-3 w-100'>
                            {_.uniqBy(tShirtList, 'id')?.length > 0 && _.uniqBy(tShirtList, 'id')?.map((item, index) => {
                                return <div className='d-flex flex-column align-items-start d-lg-none w-100 border rounded p-3 mb-3' key={`tshirt-${index}`}>
                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                        <div className='d-grid'>
                                            <span className='fw-600 text-truncate'>{item?.tshirt_name}</span>
                                        </div>
                                        <FiEdit3 role='button' className='text-secondary' size={20} onClick={() => handleEditTShirtModal(item)} />
                                    </div>
                                    <span className='fs-14 text-secondary'>Tournament</span>
                                    <span className='fw-600'>
                                        {item?.tournament?.length > 0 ?
                                            item?.tournament?.map(tournament => tournament?.name).length > 2 ?
                                                <>
                                                    {_.join(_.slice(item?.tournament?.map(tournament => tournament?.name), 0, 2), ', ')}
                                                    <span role="button" className='text-primary ms-1' onClick={() => handleViewTournamentModal(item?.tournament)}>+{item?.tournament?.map(tournament => tournament?.name).length - 2} more</span>
                                                </>
                                                :
                                                _.join(item?.tournament?.map(tournament => tournament?.name), ', ') : ""
                                        }
                                    </span>
                                    <hr className='w-100' />
                                    {tShirtList?.filter(tShirt => tShirt?.id === item?.id)?.length > 0 && tShirtList?.filter(tShirt => tShirt?.id === item?.id)?.map((sizeItem, sizeIndex) => {
                                        return <div className='d-flex justify-content-between align-items-center w-100' key={`size-${index}-${sizeIndex}`}>
                                            <span className='fw-600'>{sizeItem?.size}</span>
                                            <span className='text-secondary'>{sizeItem?.quantity}</span>
                                            <Form.Check
                                                type="switch"
                                                checked={!sizeItem?.is_out_of_stock}
                                                onChange={() => stockStatusUpdate(sizeItem?.tshirtDetailId)}
                                            />
                                        </div>
                                    })}
                                </div>
                            })}
                        </div>
                        <ReactPaginate
                            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                <span className='fs-14 fw-600 me-2'>Next</span>
                                <AiOutlineArrowRight className='text-secondary' size={20} />
                            </div>}
                            breakLabel='...'
                            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                <span className='fs-14 fw-600'>Previous</span>
                            </div>}
                            pageRangeDisplayed={3}
                            forcePage={currentPage}
                            marginPagesDisplayed={3}
                            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                            breakClassName='page-item me-3 d-flex align-items-center'
                            nextClassName='page-item next-item flex-grow-1 text-end'
                            previousClassName='page-item prev-item flex-grow-1 me-3'
                            disabledLinkClassName='btn disabled p-0 border-0'
                            pageCount={totalEntry || 1}
                            onPageChange={page => handlePagination(page)}
                            containerClassName='w-100 pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                        />
                    </>
                    :
                    <div className='w-100 text-center'>
                        <img className='img-fluid mt-5 mb-3' src={noTShirt} alt='No TShirt' />
                        <p className='fs-24 fw-600'>There are no t-shirts at this moment</p>
                    </div>
                }
            </div>
            :
            <div className='text-center mb-3'>
                <img className='img-fluid mt-5 mb-3' src={noTShirt} alt='No TShirt' />
                <p className='fs-24 fw-600'>There are no t-shirts at this moment</p>
            </div>
        }

        {/* Add T-Shirt Modal */}
        <AddTShirtModal
            show={addTShirtModal}
            handleClose={handleAddTShirtModal}
            fetchTShirtList={fetchTShirtList}
            fetchAllTShirtList={fetchAllTShirtList}
            size='lg'
        />

        {/* Edit T-Shirt Modal */}
        <EditTShirtModal
            show={editTShirtModal}
            handleClose={handleEditTShirtModal}
            fetchTShirtList={fetchTShirtList}
            fetchAllTShirtList={fetchAllTShirtList}
            selectedItem={selectedItem}
            size='lg'
        />

        {/* View Tournament Modal */}
        <ViewTournamentModal
            show={viewTournamentModal}
            handleClose={handleViewTournamentModal}
            selectedTournamentList={selectedTournamentList}
            size='lg'
        />
    </HelmetProvider>
};

export default TShirtSetting;