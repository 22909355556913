import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import moment from 'moment';

const DatePickerWithYearAndMonth = (props) => {
    const months = moment.months();

    const [years, setYears] = useState(Array.from({ length: 100 }, (_, i) => moment().year() - (99 - i)));

    /**
        * @function addYearToList
        * @params newYear
        * @description used to add year in dropdown
    */
    const addYearToList = (newYear) => {
        if (newYear && !years.includes(newYear)) {
            console.log("setYears")
            setYears((prevYears) => [...prevYears, newYear]);
        }
    };

    useEffect(() => {
        const selectedYear = moment(props.selected).year();
        addYearToList(selectedYear);
        // eslint-disable-next-line
    }, [props.selected]);

    const currentDate = moment();

    const reactSelectCustomStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        },
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#CCCCCC'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#CCCCCC'
        })
    }

    return <DatePicker
        {...props}
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth
        }) => {
            const selectedMonth = moment(date).month();
            const selectedYear = moment(date).year();

            const disableDecreaseMonth = (selectedYear === currentDate.year() - 100 && selectedMonth === 0);
            const disableIncreaseMonth = props.maxDate && moment(date).isSame(props.maxDate, 'month') && selectedYear === currentDate.year();

            /**
                * @function handleMonthChange
                * @params month
                * @description used to handle month changes and add the year to list if needed
            */
            const handleMonthChange = (month) => {
                const newDate = moment(date).add(month, 'months');
                const newYear = newDate.year();

                if (newYear !== selectedYear) {
                    addYearToList(newYear);
                }
            };

            return <>
                <div className="d-flex justify-content-between align-items-center fs-16 fw-bold m-3">
                    <BsFillArrowLeftCircleFill
                        role="button"
                        size={20}
                        className={`me-3 text-primary ${disableDecreaseMonth ? 'pe-none opacity-50' : ''}`}
                        onClick={() => {
                            handleMonthChange(-1);
                            decreaseMonth();
                        }}
                        disabled={disableDecreaseMonth}
                    />
                    <div className="d-flex align-items-center">
                        <span className="me-1">{months[selectedMonth]}</span>
                        <span>{selectedYear}</span>
                    </div>
                    <BsFillArrowRightCircleFill
                        role="button"
                        size={20}
                        className={`ms-3 text-primary ${disableIncreaseMonth ? 'pe-none opacity-50' : ''}`}
                        onClick={() => {
                            handleMonthChange(1);
                            increaseMonth();
                        }}
                        disabled={disableIncreaseMonth}
                    />
                </div>
                <div className="d-flex justify-content-center align-items-center m-3">
                    <Select
                        className='me-2'
                        styles={reactSelectCustomStyle}
                        value={{
                            value: moment(date).year(),
                            label: moment(date).year()
                        }}
                        options={years?.map((item) => {
                            return {
                                value: item,
                                label: item
                            }
                        })}
                        onChange={({ value }) => {
                            const newYear = parseInt(value);
                            changeYear(newYear);
                            addYearToList(newYear);
                        }}
                        isSearchable={true}
                    />

                    <Select
                        styles={reactSelectCustomStyle}
                        value={{
                            value: months[moment(date).month()],
                            label: months[moment(date).month()]
                        }}
                        options={months?.map((item) => {
                            return {
                                value: item,
                                label: item
                            }
                        })}
                        onChange={({ value }) => changeMonth(months.indexOf(value))}
                        isSearchable={true}
                    />
                </div>
            </>
        }}
        selected={props.selected}
        onChange={props.onChange}
        customInput={props.customInput}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        maxDate={props?.maxDate}
        className="form-control"
        wrapperClassName="w-100"
    />
};

export default DatePickerWithYearAndMonth;