import { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Custom Component
import { PrimaryButton } from '../Button';
import DatePickerWithYearAndMonth from '../DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import ErrorText from '../TextField/ErrorText'
import SuccessAlert from '../Alert/SuccessAlert'
import ErrorAlert from '../Alert/ErrorAlert'

// API Service
import { editOrganizerPlayerService } from '../../service/tournament.service';
import { getOrganizerTShirtService } from '../../service/tshirt.service';

// Redux-Functions
import { selectUser } from '../../redux/userSlice';

const EditPlayerModal = (props) => {
    const user = useSelector(selectUser);

    const [tShirtList, setTShirtList] = useState([]);
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });
    const allWatchedValues = watch({
        nest: true
    });

    const countryList = [
        "Malaysia",
        "Denmark",
        "China",
        "Thailand",
        "Singapore",
        "Indonesia",
        "Vietnam",
        "Philippines",
        "Cambodia",
        "India",
        "England",
        "Japan",
        "South Korea",
        "Taiwan",
        "Hong Kong",
        "Others"
    ]

    const reactSelectCustomStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        },
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#CCCCCC'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#CCCCCC'
        })
    }

    // for setting default values
    useEffect(() => {
        if (props?.selectedData) {
            let defaultValues = {};
            defaultValues.player_name = props?.selectedData?.player_name
            defaultValues.club_name = props?.selectedData?.club_name
            defaultValues.player_email = props?.selectedData?.player_email
            defaultValues.player_contact = props?.selectedData?.player_contact
            defaultValues.player_gender = props?.selectedData?.player_gender
            defaultValues.player_dob = new Date(props?.selectedData?.player_dob)
            defaultValues.player_idcard = props?.selectedData?.player_idcard
            defaultValues.nationality = props?.selectedData?.nationality
            if (props?.selectedData?.dupr_rating) {
                defaultValues.dupr_rating = props?.selectedData?.dupr_rating
            }
            defaultValues.proffesional_player = props?.selectedData?.proffesional_player
            if (props?.selectedData?.tshirt_details) {
                defaultValues.tshirt_id = {
                    value: props?.selectedData?.tshirt_details?.tshirt?.id,
                    label: props?.selectedData?.tshirt_details?.tshirt?.tshirt_name
                }
                defaultValues.tshirt_size_id = {
                    value: props?.selectedData?.tshirt_details?.size?.id,
                    label: props?.selectedData?.tshirt_details?.size?.size
                }
            }
            reset({ ...defaultValues })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    /**
        * @function fetchOrganizerTShirt
        * @params
        * @description fetch the single tournament details for organizer
    */
    const fetchOrganizerTShirt = async () => {
        try {
            const result = await getOrganizerTShirtService({
                id: props?.tournament?.id
            });
            if (result?.data?.status) {
                setTShirtList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (props?.tournament?.id && user?.type === 'organizer') {
            fetchOrganizerTShirt()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to edit player details from organizer
    */
    const onSubmit = async (formdata) => {
        const data = {
            player_name: formdata?.player_name,
            club_name: formdata?.club_name,
            player_email: formdata?.player_email,
            player_contact: formdata?.player_contact,
            player_gender: formdata?.player_gender,
            player_dob: moment(formdata?.player_dob).format("YYYY-MM-DD"),
            player_idcard: formdata?.player_idcard,
            nationality: formdata?.nationality,
            ...(props?.tournament?.sports_type === "PICKLEBALL" && {
                dupr_rating: formdata?.dupr_rating
            }),
            proffesional_player: formdata?.proffesional_player ? 1 : 0,
        };

        if (formdata?.tshirt_id?.value) {
            data.tshirt_details = {
                tshirt_id: formdata?.tshirt_id?.value,
                tshirt_size_id: formdata?.tshirt_size_id?.value
            };
        }
        try {
            setLoading(true)
            const result = await editOrganizerPlayerService({
                id: props?.selectedData?.id,
                data
            });
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchOrganizerPlayerList(props?.currentPage + 1)
                reset()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <Modal show={props.show} onHide={props.handleClose} centered size="lg">
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-24 fw-600 border-0' closeButton>
                Edit Player
            </Modal.Header>
            <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
                <div className='w-100'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Player Name"
                                        {...register(`player_name`, {
                                            required: "Player name is required",
                                        })}
                                    />
                                    {errors?.player_name && <ErrorText>{errors?.player_name?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Club Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Club Name"
                                        {...register(`club_name`)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {!props?.tournament?.organizer?.is_tshirt_disabled &&
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>T-Shirt</Form.Label>
                                        <Controller
                                            name={`tshirt_id`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    placeholder="Select T-shirt"
                                                    styles={reactSelectCustomStyle}
                                                    options={tShirtList?.map((tShirt) => {
                                                        return {
                                                            value: tShirt?.id,
                                                            label: tShirt?.tshirt_name
                                                        }
                                                    })}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    {...field}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption)
                                                        setValue(`tshirt_size_id`, null)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>T-Shirt Size</Form.Label>
                                        <Controller
                                            name={`tshirt_size_id`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    placeholder="Select here"
                                                    styles={reactSelectCustomStyle}
                                                    options={tShirtList?.find(tShirt => tShirt?.id === allWatchedValues?.tshirt_id?.value)?.tshirt_details?.map((tShirt) => {
                                                        return {
                                                            value: tShirt?.id,
                                                            label: tShirt?.size,
                                                            isDisabled: tShirt?.is_out_of_stock || tShirt?.quantity === 0
                                                        }
                                                    })}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: allWatchedValues?.tshirt_id?.value ? 'T-Shirt Size is required' : false
                                            }}
                                        />
                                        {errors?.tshirt_size_id && <ErrorText>{errors?.tshirt_size_id?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        {...register(`player_email`, {
                                            required: "Player Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                    {errors?.player_email && <ErrorText>{errors?.player_email?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Contact Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter Player Contact Number"
                                        {...register(`player_contact`, {
                                            required: "Player Contact Number is required",
                                            minLength: {
                                                value: 9,
                                                message: "Contact Number should be 9 digit minimum"
                                            },
                                            maxLength: {
                                                value: 11,
                                                message: "Contact Number should be 11 digit maximum"
                                            }
                                        })}
                                    />
                                    {errors?.player_contact && <ErrorText>{errors?.player_contact?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Gender</Form.Label>
                                    <Form.Select
                                        {...register(`player_gender`, {
                                            required: "Player Gender is required"
                                        })}
                                        disabled
                                    >
                                        <option value='MALE'>Male</option>
                                        <option value='FEMALE'>Female</option>
                                    </Form.Select>
                                    {errors?.player_gender && <ErrorText>{errors?.player_gender?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className="d-flex flex-column align-items-start my-2">
                                    <Form.Label className='text-fs-14 fw-500'>Date of Birth</Form.Label>
                                    <Controller
                                        control={control}
                                        name={`player_dob`}
                                        render={({ field }) => (
                                            <DatePickerWithYearAndMonth
                                                {...field}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                maxDate={new Date()}
                                            />
                                        )}
                                        rules={{ required: 'Date of Birth is required' }}
                                    />
                                    {errors?.player_dob && <ErrorText>{errors?.player_dob?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={props?.tournament?.sports_type === "PICKLEBALL" ? 4 : 6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player IC/Passport Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Player IC/Passport Number"
                                        {...register(`player_idcard`, {
                                            required: "Player IC/Passport Number is required",
                                        })}
                                    />
                                    {errors?.player_idcard && <ErrorText>{errors?.player_idcard?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={props?.tournament?.sports_type === "PICKLEBALL" ? 4 : 6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Country</Form.Label>
                                    <Form.Select
                                        {...register(`nationality`, {
                                            required: "Player Country is required",
                                        })}
                                    >
                                        <option value="">Select player country</option>
                                        {countryList?.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })}
                                    </Form.Select>
                                    {errors?.nationality && <ErrorText>{errors?.nationality?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            {props?.tournament?.sports_type === "PICKLEBALL" &&
                                <Col xs={12} lg={4}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>DUPR Rating</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter here"
                                            {...register(`dupr_rating`)}
                                        />
                                    </Form.Group>
                                </Col>
                            }
                        </Row>

                        <Form.Check
                            className='mb-2'
                            type='checkbox'
                            label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                Are you a State/District/National Player?
                            </span>}
                            id={`proffesional_player`}
                            {...register(`proffesional_player`)}
                        />

                        <Form.Check
                            type='checkbox'
                            label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                Declare that the information shared are true and confirm.
                            </span>}
                            id={`declare`}
                            required
                        />

                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Edit Player</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default EditPlayerModal;