import { useState, useEffect, useRef } from 'react'
import { Row, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import { jsPDF } from 'jspdf';
import { CSVLink } from "react-csv";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import moment from 'moment';
import { debounce } from "lodash";
import classNames from "classnames";

// Custom Component
import NoEarning from "./NoEarning";
import ErrorAlert from '../Alert/ErrorAlert';
import Receipt from '../Receipt/Receipt';

// Images
import { GenerateReceipt } from "../../assets/images/svg";

// Fonts
import PoppinsRegular from '../../assets/fonts/PoppinsRegular';
import PoppinsMedium from '../../assets/fonts/PoppinsMedium';
import PoppinsBold from '../../assets/fonts/PoppinsBold';

// Utils
import { convertEventType, convertAgeGroup, convertGender } from '../../utils/helper';

// API Service
import { allEarningService, singleReceiptOrganizerService } from '../../service/tournament.service';

// Redux-Functions
import { setPdfLoading } from "../../redux/tournamentSlice"

const MyEarning = (props) => {
    let { tournamentId } = useParams();
    const dispatch = useDispatch();

    const [earningList, setEarningList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [selectedGender, setSelectedGender] = useState('');
    const [csvData, setCSVData] = useState([]);
    const [receiptInfo, setReceiptInfo] = useState();
    const [pdfContentShow, setPdfContentShow] = useState(false)

    const pdfRef = useRef(null);

    const csvHeaders = [
        {
            label: 'Date and Time',
            key: 'created_at'
        },
        {
            label: 'Username',
            key: 'username'
        },
        {
            label: 'Phone number',
            key: 'phone'
        },
        {
            label: 'Team ID',
            key: 'team_id'
        },
        {
            label: 'Events',
            key: 'event_type'
        },
        {
            label: 'Price',
            key: 'event_price'
        }
    ];

    const { register, watch, setValue } = useForm({
        mode: "all",
        defaultValues: {
            event_type: '',
            keyword: ''
        }
    })

    const watchEventType = watch("event_type")
    const watchKeyword = watch("keyword")

    /**
        * @function handleGender
        * @params gender
        * @description set the value of gender
    */
    const handleGender = (gender) => {
        setSelectedGender(gender)
    }

    /**
        * @function handleSingleReceipt
        * @params customerId, receipt_no
        * @description get the value of receipt
    */
    const handleSingleReceipt = async (customerId, receipt_no) => {
        try {
            const result = await singleReceiptOrganizerService({
                customerId: customerId || 0,
                tournamentId
            });
            if (result?.data?.status) {
                setReceiptInfo(result?.data?.data?.find(item => item?.players?.length > 0 && item?.players[0]?.receipt_no === receipt_no))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function replaceSvgWithBase64
        * @params
        * @description used to convert svg into img
    */
    const replaceSvgWithBase64 = async () => {
        const svgs = pdfRef.current.querySelectorAll('svg');

        // Use Promise.all to wait for all SVG replacements
        await Promise.all(
            Array.from(svgs).map((svg) => {
                return new Promise((resolve) => {
                    const svgString = new XMLSerializer().serializeToString(svg);
                    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
                    const url = URL.createObjectURL(svgBlob);
                    const img = new Image();

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = svg.clientWidth;
                        canvas.height = svg.clientHeight;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);
                        const base64Image = canvas.toDataURL('image/png');

                        // Replace the SVG element with an image
                        const imgElement = document.createElement('img');
                        imgElement.src = base64Image;
                        imgElement.style.width = `${svg.clientWidth}px`;
                        imgElement.style.height = `${svg.clientHeight}px`;

                        // Check if the SVG has a class attribute and copy it
                        if (svg.getAttribute('class')) {
                            imgElement.setAttribute('class', svg.getAttribute('class'));
                        }

                        svg.parentNode.replaceChild(imgElement, svg);
                        resolve();
                    };

                    img.src = url;
                });
            })
        );
    };

    /**
        * @function handleDownload
        * @params
        * @description used to download receipt
    */
    const handleDownload = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            dispatch(setPdfLoading(true))
            setPdfContentShow(true)
        }, 1000);
        setTimeout(async () => {
            await replaceSvgWithBase64();
            const content = pdfRef.current;
            const doc = new jsPDF({
                orientation: 'p',
                unit: 'px',
                putOnlyUsedFonts: true
            })
            doc.internal.pageSize.width = content?.offsetWidth;
            doc.internal.pageSize.height = content?.offsetHeight + 10;
            doc.addFileToVFS('Poppins-Regular.ttf', PoppinsRegular)
            doc.addFileToVFS('Poppins-Medium.ttf', PoppinsMedium)
            doc.addFileToVFS('Poppins-Bold.ttf', PoppinsBold)
            doc.addFont('Poppins-Regular.ttf', 'Poppins', 'normal')
            doc.addFont('Poppins-Medium.ttf', 'Poppins', 'medium')
            doc.addFont('Poppins-Bold.ttf', 'Poppins', 'bold')
            doc.setFont('Poppins')
            doc.html(content, {
                callback: async (d) => {
                    const res = await d.save(`${props?.tournament?.name} reciept`)
                    dispatch(setPdfLoading(false))
                    setPdfContentShow(false)
                    return res
                },
                html2canvas: {
                    scale: 1,
                    allowTaint: true
                }
            })
        }, 2000)
    }

    useEffect(() => {
        if (receiptInfo) {
            handleDownload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiptInfo])

    /**
        * @function fetchEarningList
        * @params page
        * @description fetch the list of earning
    */
    const fetchEarningList = async (page) => {
        try {
            dispatch(setPdfLoading(true))
            const result = await allEarningService({
                id: tournamentId,
                gender: selectedGender,
                event_type: watchEventType,
                paginate: 1,
                page,
                perPage: 10,
                keyword: watchKeyword
            });
            if (result?.data?.status) {
                setEarningList(result?.data?.data?.player_lists)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch(setPdfLoading(false))
        } catch (error) {
            ErrorAlert(error)
            dispatch(setPdfLoading(false))
        }
    }

    /**
        * @function fetchAllEarningList
        * @params
        * @description fetch the list of all earning
    */
    const fetchAllEarningList = async () => {
        try {
            const result = await allEarningService({
                id: tournamentId,
                gender: '',
                event_type: '',
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                if (result?.data?.data?.player_lists?.length > 0) {
                    setCSVData(result?.data?.data?.player_lists?.map(item => {
                        return {
                            created_at: item?.created_at ? moment(item?.created_at).format("MMM DD YYYY, hh:mm A") : '-',
                            username: item?.customer?.username || '-',
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            team_id: item?.team_id || '-',
                            event_type: (!item?.team_event_type ? `Individual - ${convertEventType(item?.event_type)}` : 'Team - ' + item?.team_event_type.map((teamItem, teamIndex) => {
                                return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                            }).join(" , ")) || '-',
                            event_price: `RM${props?.tournament?.category_type === "TEAM" ? item?.tournament?.team_event_price : item?.event_price}` || '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (tournamentId) {
            fetchEarningList(currentPage + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, currentPage])

    useEffect(() => {
        if (tournamentId) {
            fetchAllEarningList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId])

    useEffect(() => {
        if (tournamentId) {
            if (currentPage === 0) {
                fetchEarningList(currentPage + 1);
            } else {
                setCurrentPage(0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, watchEventType, watchKeyword, selectedGender])

    const columns = [
        {
            name: 'Date and Time',
            selector: (row) => row?.created_at ? moment(row?.created_at).format("MMM DD YYYY, hh:mm A") : '-',
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row?.customer?.username ? <span className='fs-14 fw-600 text-capitalize'>{row?.customer?.username}</span> : '-',
            sortable: true
        },
        {
            name: 'Phone number',
            selector: (row) => row?.customer?.phone ? <span className='fs-14 text-secondary'>{row?.customer?.phone}</span> : '-',
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row?.customer?.gender ? <span className='fs-14 text-secondary'>{convertGender(row?.customer?.gender)}</span> : '-',
            sortable: true
        },
        {
            name: 'Events',
            selector: (row) => row.event_type ? <div className='d-flex flex-column align-items-start fs-14 fw-400 text-secondary'>
                {(props?.tournament?.category_type === "INDIVIDUAL") ?
                    <span>Individual - </span>
                    :
                    <span>Team - </span>
                }
                {(row?.team_event_type) ?
                    <span>{row?.team_event_type?.map((teamItem, teamIndex) => {
                        return `(${convertEventType(teamItem)}${(row?.team_event_age_group?.length > 0 && row?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(row?.team_event_age_group[teamIndex], row?.team_event_age_value[teamIndex])})` : ""})`
                    })?.join(", ")}</span>
                    :
                    <span>
                        {row?.event_type && `(${convertEventType(row?.event_type)}${(row?.age_group && row?.age_value) ? ` (${convertAgeGroup(row?.age_group, row?.age_value)})` : ""})`}
                    </span>
                }
            </div> : '-',
            sortable: true,
        },
        {
            name: 'Price',
            selector: (row) => row?.event_price ? <span className='fs-14 fw-600 text-capitalize'>RM{props?.tournament?.category_type === "TEAM" ? row?.tournament?.team_event_price : row?.event_price}</span> : '-',
            sortable: true
        },
        {
            name: 'Generate Receipts',
            selector: (row) => <GenerateReceipt role='button' onClick={() => handleSingleReceipt(row?.customer_id, row?.receipt_no)} />,
            sortable: true
        }
    ]

    /**
        * @function handlePagination
        * @params page
        * @description used to handle Pagination
    */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    /**
        * @function CustomPagination
        * @params
        * @description used for Custom Pagination
    */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <Row className='my-3 px-3 px-lg-0'>
        <div className='d-flex flex-column flex-lg-row align-items-start mb-3'>
            <div className='d-flex justify-content-between align-items-center mb-3 w-100'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <span className='fs-24 fw-600 me-2'>Earnings</span>
                    <span className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded me-5'>{csvData?.length || earningList?.length || 0} earnings</span>
                </div>
                <CSVLink className="text-decoration-none" filename={`${props?.tournament?.name || "my"}-Earnings.csv`} data={csvData} headers={csvHeaders}>
                    <span role='button' className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                </CSVLink>
            </div>
        </div>
        <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center flex-grow-1 mb-3'>
            <div className='d-flex flex-column flex-md-row align-items-md-center flex-grow-1 mb-3 mb-md-0'>
                <div className='d-flex align-items-center fs-14 text-secondary mb-3 mb-md-0 me-3'>
                    <span role='button' className={classNames('me-2', {
                        "text-primary fw-600": selectedGender === ''
                    })} onClick={() => handleGender('')}>All</span>
                    <span role='button' className={classNames('me-2', {
                        "text-primary fw-600": selectedGender === 'MALE'
                    })} onClick={() => handleGender('MALE')}>Male</span>
                    <span role='button' className={classNames({
                        "text-primary fw-600": selectedGender === 'FEMALE'
                    })} onClick={() => handleGender('FEMALE')}>Female</span>
                </div>
                <Form.Group className='me-md-3'>
                    <Form.Select
                        {...register("event_type")}
                    >
                        <option value="">Events</option>
                        {props?.eventList?.length > 0 && props?.eventList?.map((item, index) => {
                            return <option key={`event${index}`} value={item?.value}>{item?.label}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
            <div className='w-xs-100 tournament-input'>
                <InputGroup className="border rounded-3">
                    <InputGroup.Text className='bg-transparent border-0'>
                        <FiSearch />
                    </InputGroup.Text>
                    <Form.Control
                        className='bg-transparent border-0 rounded-3 px-0 search-input'
                        placeholder='Search user name, phone number,'
                        {...register("keyword")}
                        onChange={debounce((e) => {
                            setValue('keyword', e.target.value)
                        }, 500)}
                    />
                </InputGroup>
            </div>
        </div>
        {earningList.length > 0 ?
            <>
                <DataTable
                    className='d-none d-lg-block'
                    columns={columns}
                    data={earningList}
                    pagination
                    paginationServer
                    paginationTotalRows={10}
                    paginationPerPage={10}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationComponent={CustomPagination}
                    paginationDefaultPage={currentPage + 1}
                />
                <div>
                    {earningList?.map((item, index) => {
                        return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={index}>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.customer?.username}</span>
                                <GenerateReceipt role='button' onClick={() => handleSingleReceipt(item?.customer_id, item?.receipt_no)} />
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary'>{moment(item?.created_at).format("MMM DD YYYY, hh:mm A")}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>{item?.customer?.gender ? convertGender(item?.customer?.gender) : '-'}</span>
                            </div>
                            <div className='w-100 mt-1 fs-14 fw-400 text-secondary'>
                                {(item?.team_event_type) ?
                                    <span>Team - </span>
                                    :
                                    <span>Individual - </span>
                                }
                                {(item?.team_event_type) ?
                                    <span>{item?.team_event_type?.map((teamItem, teamIndex) => {
                                        return `(${convertEventType(teamItem)}${(item?.team_event_age_group?.length > 0 && item?.team_event_age_value?.length > 0) ? ` (${convertAgeGroup(item?.team_event_age_group[teamIndex], item?.team_event_age_value[teamIndex])})` : ""})`
                                    })?.join(", ")}</span>
                                    :
                                    <span>
                                        {item?.event_type && `(${convertEventType(item?.event_type)}${(item?.age_group && item?.age_value) ? ` (${convertAgeGroup(item?.age_group, item?.age_value)})` : ""})`}
                                    </span>
                                }
                            </div>
                            <div className='d-flex justify-content-end align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-600'>Price : RM{props?.tournament?.category_type === "TEAM" ? item?.tournament?.team_event_price : item?.event_price}</span>
                            </div>
                        </div>
                    })}
                    <ReactPaginate
                        nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <span className='fs-14 fw-600 me-2'>Next</span>
                            <AiOutlineArrowRight className='text-secondary' size={20} />
                        </div>}
                        breakLabel='...'
                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                            <span className='fs-14 fw-600'>Previous</span>
                        </div>}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        marginPagesDisplayed={3}
                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                        breakClassName='page-item me-3 d-flex align-items-center'
                        nextClassName='page-item next-item flex-grow-1 text-end'
                        previousClassName='page-item prev-item flex-grow-1 me-3'
                        disabledLinkClassName='btn disabled p-0 border-0'
                        pageCount={totalEntry || 1}
                        onPageChange={page => handlePagination(page)}
                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                    />
                    <Receipt pdfRef={pdfRef} pdfContentShow={pdfContentShow} tournament={receiptInfo} />
                </div>
            </>
            :
            <NoEarning />
        }
    </Row>
};

export default MyEarning;